import React, { useState, useEffect, useRef } from "react";
import '../../assets/style.css';
import Config from "../../core/config/";
import { makeRequest, makeRequestWithErrorMessage } from "../../core/services/v1/request";
import $ from "jquery";
import NavbarOne from "../separate/NavbarOne";
import { showNumber, showPairNameUsdt } from "../../core/helper/date-format";
import { useContextData } from "../../core/context";
import { toast } from "../../core/lib/toastAlert";
import axios from "axios";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import LoaderSpin from "../../Component/Loader/LoaderSpin";
import LoaderUnitic from "../../Component/Loader/LoaderUnitic";
import { countDecimals } from "../../core/helper/common";
import SpotMyOrdersUsdt from "../separate/usdtTrading/SpotMyOrdersUsdt";
import SpotOrderBookUsdt from "../separate/usdtTrading/SpotOrderBookUsdt";
import SpotRecentUsdt from "../separate/usdtTrading/SpotRecentUsdt";
import SpotMenuUsdt from "../separate/usdtTrading/SpotMenuUsdt";
import TradingViewSpot from "../separate/usdtTrading/tradeChartUsdt/trading-view-usdt";
import SpotTradeFormUsdt from "../separate/usdtTrading/SpotTradeFormUsdt";
import OrderConfirmationUsdt from "../separate/usdtTrading/dialogsUsdt/OrderConfirmationUsdt"
import ModifyOrderUsdt from "../separate/usdtTrading/dialogsUsdt/ModifyOrderUsdt"
import ModifyMarginUsdt from "../separate/usdtTrading/dialogsUsdt/ModifyMarginUsdt"
import AdjustLeverageUsdt from "../separate/usdtTrading/dialogsUsdt/AdjustLeverageUsdt"
import MarginModeUsdt from "../separate/usdtTrading/dialogsUsdt/MarginModeUsdt"
import SpotInfoUsdt from "../separate/usdtTrading/infoUsdt/InfoUsdt"
import SpotTradingDataUsdt from "../separate/usdtTrading/SpotTradingDataUsdt"
import socketIOClient from "socket.io-client";
import { getCookie } from "../../core/helper/cookie";

function formatNumber(num = 0) {
    num = Number(num);
    if (num == 0) return 0;
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const factor = 1000;

    let suffixIndex = 0;
    let formattedNumber = num;

    while (formattedNumber >= factor && suffixIndex < suffixes.length - 1) {
        formattedNumber /= factor;
        suffixIndex++;
    }
    return `${formattedNumber.toFixed(2)}${suffixes[suffixIndex]}`;
}
function createSymbolMap(symbolArray = null) {
    return symbolArray.reduce((map, symbolData) => {
        map[symbolData.symbol] = symbolData;
        return map;
    }, {});
}

function decimalValue(value, decimal = 0) {
    if (decimal === 0) {
        return showNumber(parseFloat(value));
    }
    return showNumber(parseFloat(value).toFixed(decimal));
}
function generateId() {
    return '_spot_' + Date.now() + (Math.random() * 365).toString(16).slice(4, 14);
}

const initialState = {
    bids: [],
    asks: [],
    ts: 0,
};
let priceObj = null;
let userTokenChk = 0;
const SpotTradeUsdt = (props) => {
    const [orderListType, setOrderListType] = useState("all");
    const containerRef = useRef(null);
    const containerSecondRef = useRef(null);
    const [pairDetails, setPairDetails] = useState({ prevPrice: 0, data: {}, market: {}, baseDecimal: null, quoteDecimal: null, priceDecimal: null, rate: null, pairDB: {} });
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isOverflowingSecond, setIsOverflowingSecond] = useState(false);
    const [currentPage_tradeHis, setCurrentPage_tradeHis] = useState(1);
    const [socketConnection, setSocketConnection] = useState(null);
    const [socketConnectionKucoin, setSocketConnectionKucoin] = useState(null);
    const [socketConnectionKucoinV2, setSocketConnectionKucoinV2] = useState(null);
    const [tradesShow, setTradesShow] = useState(0);
    const [firstOptionPart, setFirstOptionPart] = useState(0);
    const [pairsData, setPairsData] = useState([]);
    const [symbolsData, setSymbols] = useState([]);
    const [pairsLoader, setPairsLoader] = useState(true)
    const [orderFetchLoading, setOrderFetchLoading] = useState(true)
    const [priceStatic, setPriceStatic] = useState()
    const [orderBookData, setOrderBookData] = useState(initialState);
    const [recentTradesData, setRecentTradesData] = useState({});
    const [walletData, setWalletData] = useState([]);
    const [trades, setTrades] = useState({ openOrders: [], stopOrders: [], orders: [], fills: [] });
    const [crossMenu, setCrossMenu] = useState('isolated');
    const [xMenu, setXMenu] = useState(1);
    const [tpPx, setTpPx] = useState();
    const [slPx, setSlPx] = useState();
    const [amount, setAmount] = useState(null);
    const [quantity, setQuantity] = useState(null);
    const [openOrderConfirmation, setOpenOrderConfirmation] = React.useState(false);
    const [tradeParams, setTradeParams] = React.useState({});
    const [favs, setFavs] = useState(null);
    const [currentPage, setCurrentPage] = useState({ openOrders: 1, stopOrders: 1, orders: 1, fills: 1 });
    const [totalPages, setTotalPages] = useState({ totalOpenOrders: 0, totalStopOrders: 0, totalOrders: 0, totalFills: 0 });

    // const { pairName } = useParams();
    const { pairName, handleNavigation, navigate } = props;
    const handleClickOpenOrderConfirmation = (tradeParams) => {
        setTradeParams(() => tradeParams)
        setOpenOrderConfirmation(true);
    };
    const handleCloseOrderConfirmation = () => {
        setOpenOrderConfirmation(false);
    };
    const [openModifyOrder, setOpenModifyOrder] = React.useState(false);

    const handleClickOpenModifyOrder = () => {
        setOpenModifyOrder(true);
    };
    const handleCloseModifyOrder = () => {
        setOpenModifyOrder(false);
    };
    const [openModifyMargin, setOpenModifyMargin] = React.useState(false);

    const handleClickOpenModifyMargin = () => {
        setOpenModifyMargin(true);
    };
    const handleCloseModifyMargin = () => {
        setOpenModifyMargin(false);
    };
    const [openAdjust, setOpenAdjust] = React.useState(false);

    const handleClickOpenAdjust = () => {
        setOpenAdjust(true);
    };
    const handleCloseAdjust = () => {
        setOpenAdjust(false);
    };
    const [openMarginMode, setOpenMarginMode] = React.useState(false);

    const handleClickOpenMarginMode = () => {
        setOpenMarginMode(true);
    };
    const handleCloseMarginMode = () => {
        setOpenMarginMode(false);
    };
    const { myProfile, setUserProfile, spotBalance, setSpotUsdtBalance, dispatch } = useContextData();
    const formatSymbolName = (baseCurrency = '', quoteCurrency = '') => {
        if (baseCurrency === 'XBT') {
            baseCurrency = 'BTC'
        }
        const quoteCurrencyEdition = <span style={{ color: '#C8C8C8' }}>{quoteCurrency}</span>
        const symbolEdition = <span className='fw-bold text-capitalize'>{baseCurrency + ' Perpetual/'}{quoteCurrencyEdition}</span>
        return symbolEdition;
    }
    function formatWithK(value, deci = 2) {
        if (!value) return value;
        value = Number(value);
        if (value >= 1000) {
            return (value / 1000).toFixed(2) + "K";
        }
        return value.toFixed(deci);
    }
    const asksModify = async (a1, a2, getBaseDecimal, getPriceDecimal) => {
        let baseDeci = getBaseDecimal || priceObj.baseDecimal || pairDetails.baseDecimal;
        let priceDeci = getPriceDecimal || priceObj.priceDecimal || pairDetails?.priceDecimal;
        if (!baseDeci) {
            baseDeci = countDecimals(a2[0][1]);
        }
        if (!priceDeci) {
            priceDeci = countDecimals(a2[0][0]);
        }
        const depthMap = new Map();
        const pricesSet = new Set();
        let totalQuantity = 0;

        // Populate depthMap with a1
        a1.reverse().forEach((depth, index, array) => {
            // if (depth.length === 4) {
            const price = priceObj?.changeValue > 0 ? (priceObj?.changePer * (+depth[0])).toFixed(priceDeci) : depth[0];
            const quantity = parseFloat(depth[1]); // Multiply by 0.01
            totalQuantity += quantity;
            const orders = formatWithK(totalQuantity, baseDeci);// Calculate orders based on cumulative sum
            depthMap.set(price, [price, formatWithK(quantity, baseDeci), orders.toString()]);
            pricesSet.add(price);
            // }
        });

        // Iterate over a2
        totalQuantity = 0; // Reset totalQuantity
        a2.reverse().forEach((depth, index, array) => {
            // if (depth.length === 4) {
            const price = priceObj?.changeValue > 0 ? (priceObj?.changePer * (+depth[0])).toFixed(priceDeci) : depth[0];
            const size = parseFloat(depth[1]); // Multiply by 0.01
            totalQuantity += size;
            const orders = formatWithK(totalQuantity, baseDeci); // Calculate orders based on cumulative sum

            if (size === 0) {
                // If size is 0, delete the depth from depthMap and pricesSet
                depthMap.delete(price);
                pricesSet.delete(price);
            } else {
                // If size is not 0
                if (pricesSet.has(price)) {
                    // If price exists in a1, update the depthMap
                    depthMap.set(price, [price, formatWithK(size, baseDeci), orders]);
                } else {
                    // If price does not exist in a1, add it to depthMap and pricesSet
                    depthMap.set(price, [price, formatWithK(size, baseDeci), orders]);
                    pricesSet.add(price);
                }
            }
            // }
        });

        // Convert depthMap back to array and sort
        const result = Array.from(depthMap.values()).sort((a, b) => parseFloat(a[0]) - parseFloat(b[0]));

        return result;
    };
    const bidsModify = async (a1, a2, getBaseDecimal, getPriceDecimal) => {
        let baseDeci = getBaseDecimal || priceObj?.baseDecimal || pairDetails?.baseDecimal;
        let priceDeci = getPriceDecimal || priceObj.priceDecimal || pairDetails?.priceDecimal;
        if (!baseDeci) {
            baseDeci = countDecimals(a2[0][1]);
        }
        if (!priceDeci) {
            priceDeci = countDecimals(a2[0][0]);
        }
        const depthMap = new Map();
        const pricesSet = new Set();
        let totalQuantity = 0;

        // Populate depthMap with a1
        a1.forEach((depth, index) => {
            // if (depth.length === 4) {
            const price = priceObj?.changeValue > 0 ? (priceObj?.changePer * (+depth[0])).toFixed(priceDeci) : depth[0];
            const quantity = parseFloat(depth[1]); // Multiply by 0.01
            totalQuantity += quantity;
            const orders = formatWithK(totalQuantity, baseDeci); // Calculate orders based on cumulative sum
            depthMap.set(price, [price, formatWithK(quantity, baseDeci), orders]); // Round to 2 decimal places
            pricesSet.add(price);
            // }
        });

        // Iterate over a2
        totalQuantity = 0; // Reset totalQuantity
        a2.forEach((depth, index) => {
            // if (depth.length === 4) {
            const price = priceObj?.changeValue > 0 ? (priceObj?.changePer * (+depth[0])).toFixed(priceDeci) : depth[0];
            const size = parseFloat(depth[1]); // Multiply by 0.01
            totalQuantity += size;
            const orders = formatWithK(totalQuantity, baseDeci); // Calculate orders based on cumulative sum

            if (size === 0) {
                // If size is 0, delete the depth from depthMap and pricesSet
                depthMap.delete(price);
                pricesSet.delete(price);
            } else {
                // If size is not 0
                if (pricesSet.has(price)) {
                    // If price exists in a1, update the depthMap
                    depthMap.set(price, [price, formatWithK(size, baseDeci), orders]); // Round to 2 decimal places
                } else {
                    // If price does not exist in a1, add it to depthMap and pricesSet
                    depthMap.set(price, [price, formatWithK(size, baseDeci), orders]); // Round to 2 decimal places
                    pricesSet.add(price);
                }
            }
            // }
        });

        // Convert depthMap back to array and sort
        const result = Array.from(depthMap.values()).sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]));

        return result;
    };
    const orderBookDataCalculation = async (oldBids, oldAsks, newBids, newAsks, getBaseDecimal = null, getQuoteDecimal = null) => {
        try {
            if (!priceObj || priceObj == null || !priceObj?.changePer || priceObj?.changePer == null) return;
            const modifiedBids = await bidsModify(oldBids, newBids, getBaseDecimal, getQuoteDecimal);
            const modifiedAsks = await asksModify(oldAsks, newAsks, getBaseDecimal, getQuoteDecimal);
            setOrderBookData(() => {
                return {
                    bids: modifiedBids,
                    asks: modifiedAsks,
                }
            })
        } catch (error) {
            console.error('Error modifying data:', error);
            setOrderBookData(() => {
                return {
                    bids: [],
                    asks: [],
                }
            })
        }
    }
    const pairDataFetch = async () => {
        setPairsLoader(true);
        await axios.get(`${Config?.V3_API_URL}trade/get-pair?pair=${pairName}`)
            .then(res => {
                if (res.data?.data?.orderBook.code != '200000' || !res.data?.status) {
                    toast({ type: "error", message: res.data?.data?.orderBook?.msg || 'An error ocurred!' });
                    return handleNavigation('BTC-USDT')
                }
                const newAsks = res.data?.data?.orderBook?.data?.asks || [];
                const newBids = res.data?.data?.orderBook?.data?.bids || [];
                const oldBids = orderBookData?.bids || [];
                const oldAsks = orderBookData?.asks || [];
                const getQuoteDecimal = countDecimals(res.data?.data?.pair?.data?.quoteIncrement);
                const getBaseDecimal = countDecimals(res.data?.data?.pair?.data?.baseIncrement);
                const getPriceDecimal = countDecimals(res.data?.data?.pair?.data?.priceIncrement);
                priceObj = res.data?.data?.rate;
                priceObj = { ...priceObj, baseDecimal: getBaseDecimal, quoteDecimal: getQuoteDecimal, priceDecimal: getPriceDecimal }
                orderBookDataCalculation(oldBids, oldAsks, newBids, newAsks, getBaseDecimal, getPriceDecimal)
                const symbols = createSymbolMap(res.data?.data?.symbols);
                setSymbols(() => symbols)
                setRecentTradesData(() => res.data?.data?.recentTrade?.data)
                // setTime(() => res.data?.data?.pair?.data?.nextFundingRateTime)
                let pairData = res.data?.data?.pair?.data;
                pairData = {
                    ...pairData,
                    price: (pairData?.rate?.changePer * (+pairData?.price)).toFixed(priceObj?.priceDecimal),
                }
                setPriceStatic(() => pairData?.price)
                const markets = (res.data?.data?.pairs).filter(data => data?.symbol.split('-')[1] === "USDT");
                let currentPair = markets.find(el => el.symbol === pairData?.symbol)
                currentPair = {
                    ...currentPair,
                    high: (pairData?.rate?.changePer * (+currentPair?.high)).toFixed(priceObj?.priceDecimal),
                    low: (pairData?.rate?.changePer * (+currentPair?.low)).toFixed(priceObj?.priceDecimal),
                }
                setPairsData(() => markets)
                setPairDetails((data) => { return { prevPrice: currentPair?.averagePrice, data: pairData, market: currentPair, baseDecimal: getBaseDecimal, quoteDecimal: getQuoteDecimal, priceDecimal: getPriceDecimal, rate: pairData?.rate, pairDB: res.data?.data?.pairDB } })
                const favAdd = markets?.filter(data => myProfile?.spotFav?.some(fav => fav === data?.symbol))
                setFavs(() => favAdd);
            })
            .catch(err => {
                console.log(err);
                return navigate('/');
            })
            .finally(() => {
                setPairsLoader(() => false)
            })
    }
    const ordersDataFetch = async () => {
        setOrderFetchLoading(() => true)
        const params = {
            url: `${Config.V3_API_URL}trade/get-orders`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades(() => response?.data)
            setTotalPages(() => response?.pages)
            let pageObj = { openOrders: 1, stopOrders: 1, orders: 1, fills: 1 }
            setCurrentPage(() => pageObj)
        }
        else {
        }
        setOrderFetchLoading(() => false)
    }
    const openOrdersDataFetch = async (curr) => {
        setOrderFetchLoading(() => true)
        setCurrentPage((el) => { return { ...el, openOrders: curr } })
        const params = {
            url: `${Config.V3_API_URL}trade/get-open-orders?pageSize=${20}&page=${curr}`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades((el) => { return { ...el, openOrders: response?.data?.openOrders } })
            setTotalPages((el) => { return { ...el, totalOpenOrders: response?.data?.totalPage } })
        }
        else {
        }
        setOrderFetchLoading(() => false)
    }
    const orderHistoryDataFetch = async (curr) => {
        setOrderFetchLoading(() => true)
        setCurrentPage((el) => { return { ...el, orders: curr } })
        const params = {
            url: `${Config.V3_API_URL}trade/get-orderHistory?pageSize=${20}&page=${curr}`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades((el) => { return { ...el, orders: response?.data?.orders } })
            setTotalPages((el) => { return { ...el, totalOrders: response?.data?.totalPage } })
        }
        else {
        }
        setOrderFetchLoading(() => false)
    }
    const stopOrderDataFetch = async (curr) => {
        setOrderFetchLoading(() => true)
        setCurrentPage((el) => { return { ...el, stopOrders: curr } })
        const params = {
            url: `${Config.V3_API_URL}trade/get-stopOrder?pageSize=${20}&page=${curr}`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades((el) => { return { ...el, stopOrders: response?.data?.stopOrders } })
            setTotalPages((el) => { return { ...el, totalStopOrders: response?.data?.totalPage } })
        }
        else {
        }
        setOrderFetchLoading(() => false)
    }
    const fillsOrderDataFetch = async (curr) => {
        setOrderFetchLoading(() => true)
        setCurrentPage((el) => { return { ...el, fills: curr } })
        const params = {
            url: `${Config.V3_API_URL}trade/get-fills?pageSize=${20}&page=${curr}`,
            method: 'GET'
        }
        const response = (await makeRequest(params));
        if (response?.status) {
            // console.log(response?.data)
            setTrades((el) => { return { ...el, fills: response?.data?.fills } })
            setTotalPages((el) => { return { ...el, totalFills: response?.data?.totalPage } })
        }
        else {
        }
        setOrderFetchLoading(() => false)
    }
    useEffect(() => {
        const favAdd = (pairsData).filter(data => myProfile?.spotFav?.some(fav => fav === data?.symbol))
        setFavs(() => favAdd)
    }, [myProfile, pairsData])

    const checkOverflow = () => {
        if (containerRef.current && containerSecondRef.current) {
            setIsOverflowing(containerRef.current.scrollWidth > containerRef.current.clientWidth);
            setIsOverflowingSecond(containerSecondRef.current.scrollWidth > containerSecondRef.current.clientWidth);
        }
    };

    useEffect(() => {
        checkOverflow();

        window.addEventListener('resize', checkOverflow);
        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [pairsData]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        pairDataFetch()
        ordersDataFetch()
        setSpotUsdtBalance();
        // getWalletCurrency();
        $(document).ready(function () {
            $(".futures-trading-table-total-height li").mouseenter(function () {
                $(this).addClass("divider");
            });
            $(".futures-trading-table-total-height li").mouseleave(function () {
                $(this).removeClass("divider");
            });
        });
        let socket = socketIOClient(Config.SOCKET_URL, {
            // transports: ["polling"],
            transports: ["websocket"],
            // rejectUnauthorized: false
        });
        let socketUnsubscribe;
        if (socket) {
            socket.on("connect", function () {
                setSocketConnection(socket);
                socketUnsubscribe = socket;
            });
            socket.on("connect_error", (err) => {
                console.log("socket connect_error", err);
            });
            socket.on("disconnect", function () {
            });
        }
        return () => {
            if (socketUnsubscribe) {
                socketUnsubscribe.disconnect();
            }
        };

    }, []);
    let socket = null;
    let heartbeatInterval = null;
    let alive = false;
    let pingTimeout = null;
    const resetPingTimeout = () => {
        if (pingTimeout) {
            clearTimeout(pingTimeout);
        }
        pingTimeout = setTimeout(() => {
            // console.log('No pong received within 10 seconds, disconnecting...');
            if (socket) {
                socket.close();
            }
        }, 10000);
    }
    const startHeartbeat = () => {
        if (heartbeatInterval) {
            clearInterval(heartbeatInterval);
        }
        heartbeatInterval = setInterval(() => {
            resetPingTimeout();
            if (socket) {

                socket.send(JSON.stringify({ id: generateId(), type: 'ping' }));
            }
        }, 59000);
    };

    const startPing = () => {
        resetPingTimeout();
        if (socket) {

            socket.send(JSON.stringify({ id: generateId(), type: 'ping' }));
        }
    };

    const stopHeartbeat = () => {
        clearInterval(heartbeatInterval);
        heartbeatInterval = null;
    };

    const stopPing = () => {
        if (pingTimeout) {
            clearTimeout(pingTimeout);

            pingTimeout = null;
        }
    };
    useEffect(() => {

        const setupWebSocket = async () => {
            try {
                if (socket) {
                    socket.close();
                    socket = null;
                }
                if (socketConnectionKucoin) {
                    socketConnectionKucoin.close();
                    setSocketConnectionKucoin(() => null)
                }
                const getTokenResponse = await axios.post(`${Config.V3_API_URL}trade/get-socket-spot`);
                const token = getTokenResponse?.data?.data?.data?.token;
                const instanceServerEndpoint = getTokenResponse?.data?.data?.data?.instanceServers[0].endpoint;

                const wssUri = `${instanceServerEndpoint}?token=${token}&acceptUserMessage=true&connectId=${generateId()}`;

                socket = new WebSocket(wssUri);


                socket.onopen = () => {
                    // Start heartbeat and ping
                    alive = true;
                    startHeartbeat();
                    startPing();
                    setSocketConnectionKucoin(() => socket)
                };

                socket.onmessage = async (event) => {
                    const message = JSON.parse(event?.data);
                    // console.log(message);
                    if (message) {
                        switch (message.type) {
                            case 'welcome':
                            case 'ack':
                                break;
                            case 'pong':
                                // Stop ping timeout
                                stopPing(pingTimeout);
                                break;
                            default:
                                await handleMessage(message)
                        }
                    }
                };

                socket.onclose = () => {
                    // console.log('WebSocket connection closed');
                    // Cleanup heartbeat and ping
                    alive = false;
                    stopHeartbeat();
                    stopPing();
                    if (socket) {
                        socket.close();
                        socket = null;
                    }
                    if (socketConnectionKucoin) {
                        socketConnectionKucoin.close();
                        setSocketConnectionKucoin(() => null)
                    }
                    setTimeout(setupWebSocket, 5000); // Retry after 5 seconds
                };

                socket.onerror = (error) => {
                    alive = false;
                    console.error('WebSocket error:', error);
                };
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        setupWebSocket();
        return () => {
            if (socket) {
                socket.close();
                socket = null;
            }
            if (socketConnectionKucoin) {
                socketConnectionKucoin.close();
                setSocketConnectionKucoin(() => null)
            }
        };
    }, []);

    let socketV2 = null;
    let heartbeatIntervalV2 = null;
    let aliveV2 = false;
    let pingTimeoutV2 = null;
    const resetPingTimeoutV2 = () => {
        if (pingTimeoutV2) {
            clearTimeout(pingTimeoutV2);
        }
        pingTimeoutV2 = setTimeout(() => {
            // console.log('No pong received within 10 seconds, disconnecting...');
            if (socketV2) {
                socketV2.close();
            }
        }, 10000);
    }
    const startHeartbeatV2 = () => {
        if (heartbeatIntervalV2) {
            clearInterval(heartbeatIntervalV2);
        }
        heartbeatIntervalV2 = setInterval(() => {
            resetPingTimeoutV2();
            if (socketV2) {

                socketV2.send(JSON.stringify({ id: generateId(), type: 'ping' }));
            }
        }, 59000);
    };

    const startPingV2 = () => {
        resetPingTimeoutV2();
        if (socketV2) {

            socketV2.send(JSON.stringify({ id: generateId(), type: 'ping' }));
        }
    };

    const stopHeartbeatV2 = () => {
        clearInterval(heartbeatIntervalV2);
        heartbeatIntervalV2 = null;
    };

    const stopPingV2 = () => {
        if (pingTimeoutV2) {
            clearTimeout(pingTimeoutV2);

            pingTimeoutV2 = null;
        }
    };
    useEffect(() => {

        const setupWebSocketV2 = async () => {
            try {
                if (socketV2) {
                    socketV2.close();
                    socketV2 = null;
                }
                if (socketConnectionKucoinV2) {
                    socketConnectionKucoinV2.close();
                    setSocketConnectionKucoinV2(() => null)
                }
                const params = {
                    url: `${Config.V3_API_URL}trade/get-socket-spot-private`,
                    method: "POST",
                };
                const getTokenResponse = await makeRequestWithErrorMessage(params);
                if (!getTokenResponse.status) {
                    return;
                }
                const token = getTokenResponse?.data?.data?.token;
                const instanceServerEndpoint = getTokenResponse?.data?.data?.instanceServers[0].endpoint;

                const wssUri = `${instanceServerEndpoint}?token=${token}&acceptUserMessage=true&connectId=${generateId()}`;

                socketV2 = new WebSocket(wssUri);


                socketV2.onopen = () => {
                    // Start heartbeat and ping
                    aliveV2 = true;
                    startHeartbeatV2();
                    startPingV2();
                    setSocketConnectionKucoinV2(() => socketV2)
                };

                socketV2.onmessage = async (event) => {
                    const message = JSON.parse(event?.data);
                    // console.log(message);
                    if (message) {
                        switch (message.type) {
                            case 'welcome':
                            case 'ack':
                                break;
                            case 'pong':
                                // Stop ping timeout
                                stopPingV2(pingTimeoutV2);
                                break;
                            default:
                                await handleMessageV2(message)
                        }
                    }
                };

                socketV2.onclose = () => {
                    // console.log('WebSocket connection closed');
                    // Cleanup heartbeat and ping
                    aliveV2 = false;
                    stopHeartbeatV2();
                    stopPingV2();
                    if (socketV2) {
                        socketV2.close();
                        socketV2 = null;
                    }
                    if (socketConnectionKucoinV2) {
                        socketConnectionKucoinV2.close();
                        setSocketConnectionKucoinV2(() => null)
                    }
                    setTimeout(setupWebSocketV2, 2000); // Retry after 5 seconds
                };

                socketV2.onerror = (error) => {
                    aliveV2 = false;
                    console.error('WebSocket error:', error);
                };
            } catch (error) {
                console.error('Error fetching token:', error);
            }
        };
        setupWebSocketV2();
        return () => {
            if (socketV2) {
                socketV2.close();
                socketV2 = null;
            }
            if (socketConnectionKucoinV2) {
                socketConnectionKucoinV2.close();
                setSocketConnectionKucoinV2(() => null)
            }
        };
    }, []);

    useEffect(() => {
        if (socketConnectionKucoin != null) {
            loadSocket();
        }
    }, [socketConnectionKucoin]);
    useEffect(() => {
        if (socketConnectionKucoinV2 != null) {
            loadSocketV2();
        }
    }, [socketConnectionKucoinV2]);
    useEffect(() => {
        if (socketConnection != null) {
            loadSocketExchange();
        }
    }, [socketConnection]);

    function decimalValueNum(value, decimal) {
        return parseFloat(value).toFixed(decimal);
    }
    function getTopic(marketString) {
        const parts = marketString.split("/");
        const level2 = parts[2];
        const level2Parts = level2.split(":");
        const extractedValue = level2Parts[0];
        return extractedValue;
    }
    const loadSocketExchange = () => {
        const userJWT = getCookie("userToken");
        if (userJWT != null && userJWT != "") {
            userTokenChk = userJWT ? userJWT : "";
            socketConnection.emit("join", { userId: userJWT });
            socketConnection.on("joined", function (data) {
                if (userJWT == data.token) {
                    userTokenChk = data.userId;
                }
            });
            socketConnection.on("balance", (data) => {
                if (data) {
                    for (const el of data?.data) {
                        if (userTokenChk == el?.userId) {
                            ordersDataFetch()
                            if (el?.currency === 'USDT') {
                                const getHold = spotBalance?.['trade']?.[el?.currency]?.holds ? (+spotBalance?.['trade']?.[el?.currency]?.holds) : 0.00
                                dispatch({
                                    type: 'SET_SPOT_AVLBALANCE',
                                    payload: { available: (el?.available)?.toFixed(4), hold: getHold, currency: el?.currency }
                                });
                            }
                        }
                    }
                }
            });

        }
    }
    function loadSocket() {
        const sendDataJSON = [
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/spotMarket/level2Depth50:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/market/match:${pairName}`,
                "privateChannel": false,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/market/ticker:${pairName}`,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": `/market/snapshot:${pairName}`,
                "privateChannel": false,
                "response": true
            }
        ]

        if (socketConnectionKucoin) {
            sendDataJSON.forEach(data => socketConnectionKucoin.send(JSON.stringify(data)))

        }

    }
    async function handleMessage(data) {

        let dataPairName = data?.topic ? (data?.topic).split(':')[1] : '';
        if (data?.data && dataPairName === pairName) {
            const getWhose = getTopic(data?.topic);
            if (getWhose) {
                if (getWhose === 'level2Depth50') {
                    const newAsks = data?.data?.asks || [];
                    const newBids = data?.data?.bids || [];
                    const oldBids = orderBookData?.bids || [];
                    const oldAsks = orderBookData?.asks || [];
                    orderBookDataCalculation(oldBids, oldAsks, newBids, newAsks, priceObj?.baseDecimal, priceObj?.priceDecimal)
                }
                if (getWhose === 'match') {
                    setRecentTradesData((oldData) => {

                        if (!Array.isArray(oldData)) {
                            oldData = [];
                        }

                        const newData = [
                            data?.data,
                            ...oldData
                        ];

                        const limitedData = newData.slice(0, 18);

                        if (JSON.stringify(limitedData) !== JSON.stringify(oldData)) {
                            return [...limitedData];
                        }

                        return oldData;
                    });
                }
                if (getWhose === 'ticker') {
                    setPairDetails((prevPairDetails) => ({
                        ...prevPairDetails,
                        data: {
                            ...prevPairDetails.data,
                            price: (priceObj?.changePer * (+data?.data?.price)).toFixed(priceObj?.priceDecimal)
                        }
                    }));
                }
                if (getWhose === 'snapshot') {
                    setPairDetails((prevPairDetails) => ({
                        ...prevPairDetails,
                        prevPrice: data?.data?.averagePrice,
                        market: {
                            ...prevPairDetails.market,
                            high: (priceObj?.changePer * (+data?.data?.data?.high)).toFixed(priceObj?.priceDecimal),
                            low: (priceObj?.changePer * (+data?.data?.data?.low)).toFixed(priceObj?.priceDecimal),
                            vol: data?.data?.data?.vol,
                            volValue: data?.data?.data?.volValue,
                            changePrice: data?.data?.data?.changePrice,
                            changeRate: data?.data?.data?.changeRate,
                            averagePrice: data?.data?.data?.averagePrice,
                            last: (priceObj?.changePer * (+data?.data?.data?.lastTradedPrice)).toFixed(priceObj?.priceDecimal)
                        }
                    }));
                }
            }
        }
    }
    function loadSocketV2() {
        const sendDataJSON = [
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/spotMarket/tradeOrdersV2",
                "privateChannel": true,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/spotMarket/advancedOrders",
                "privateChannel": true,
                "response": true
            },
            {
                "id": generateId(),
                "type": "subscribe",
                "topic": "/account/balance",
                "privateChannel": true,
                "response": true
            }
        ]

        if (socketConnectionKucoinV2) {
            sendDataJSON.forEach(data => socketConnectionKucoinV2.send(JSON.stringify(data)))

        }

    }
    async function handleMessageV2(data) {
        // console.log(data)
        // dataPairName=dataPairName.split('_')[0]
        if (data?.subject === 'account.balance') {
            if (data && data?.data?.relationEvent.split('.')[0] == 'main') {
                dispatch({
                    type: 'SET_SPOT_MAIN',
                    payload: { available: data?.data?.available, hold: data?.data?.hold, currency: data?.data?.currency }
                });
            }
            else if (data && data?.data?.relationEvent.split('.')[0] == 'trade') {
                if (data?.data?.currency === 'USDT') {
                    const getAvailable = spotBalance?.['trade']?.[data?.data?.currency]?.available ? (+spotBalance?.['trade']?.[data?.data?.currency]?.available) : 0.00
                    dispatch({
                        type: 'SET_SPOT_AVLBALANCE',
                        payload: { available: getAvailable, hold: data?.data?.hold, currency: data?.data?.currency }
                    });
                }
                else {
                    dispatch({
                        type: 'SET_SPOT_AVLBALANCE',
                        payload: { available: data?.data?.available, hold: data?.data?.hold, currency: data?.data?.currency }
                    });
                }
            }
        }
        if (data?.subject === 'stopOrder') {
            setTrades((prev) => {
                const newOrder = data?.data;

                if (!newOrder) return prev;

                const orderId = newOrder.orderId;
                if (newOrder.type === 'active' || newOrder.type === 'open') {
                    const updatedStopOrders = prev.stopOrders.some(order => order.orderId === orderId)
                        ? prev.stopOrders.map(order => order.orderId === orderId ? newOrder : order)
                        : [...prev.stopOrders, newOrder];

                    const final = {
                        ...prev,
                        stopOrders: updatedStopOrders,
                    };

                    return final;
                }

                else if (newOrder.type === 'done' || newOrder.type === 'triggered' || newOrder.type === 'cancel') {
                    const updatedOrders = prev.orders
                        ? prev.orders.map(order => order.orderId === orderId ? newOrder : order)
                        : [newOrder];


                    const updatedStopOrders = prev.stopOrders
                        ? prev.stopOrders.filter(order => (order.orderId || order.id) !== (orderId || newOrder?.orderId || newOrder?.id))
                        : [];

                    const final = {
                        ...prev,
                        orders: prev.orders.some(order => order.orderId === orderId)
                            ? updatedOrders
                            : [newOrder, ...updatedOrders],

                        stopOrders: updatedStopOrders
                    };
                    return final;
                }
                else {
                    return prev;
                }
            });
        }

        if (data?.subject === 'orderChange') {
            setTrades((prev) => {
                const newOrder = data?.data;

                if (!newOrder) return prev;

                const clientOid = newOrder.clientOid;
                if (newOrder.status === 'active' || newOrder.status === 'open') {
                    const updatedOpenOrders = prev.openOrders.some(order => order.clientOid === clientOid)
                        ? prev.openOrders.map(order => order.clientOid === clientOid ? newOrder : order)
                        : [...prev.openOrders, newOrder];

                    const final = {
                        ...prev,
                        openOrders: updatedOpenOrders,
                    };

                    return final;
                }

                else if (newOrder.status === 'done') {
                    const updatedOrders = prev.orders
                        ? prev.orders.map(order => order.clientOid === clientOid ? newOrder : order)
                        : [newOrder];

                    const updatedFilledOrders = prev.fills
                        ? prev.fills.map(order => order.clientOid === clientOid ? newOrder : order)
                        : [newOrder];

                    const updatedOpenOrders = prev.openOrders
                        ? prev.openOrders.filter(order => order.clientOid !== clientOid)
                        : [];

                    const updatedStopOrders = prev.stopOrders
                        ? prev.stopOrders.filter(order => (order.orderId || order.id) !== (clientOid || newOrder?.orderId || newOrder?.id))
                        : [];

                    const isCompleteOrder = newOrder.filledSize == newOrder.size;
                    const final = {
                        ...prev,
                        orders: prev.orders.some(order => order.clientOid === clientOid)
                            ? updatedOrders
                            : [newOrder, ...updatedOrders],

                        fills: isCompleteOrder
                            ? prev.fills.some(order => order.clientOid === clientOid)
                                ? updatedFilledOrders
                                : [newOrder, ...updatedFilledOrders] : prev.fills,

                        openOrders: updatedOpenOrders,
                        stopOrders: updatedStopOrders
                    };
                    return final;
                }
                else {
                    return prev;
                }
            });
        }

    }
    async function cancelOrder(ordId, isWait = false, type = 'order') {

        const params = {
            url: `${Config.V3_API_URL}trade/cancel-order/${ordId}?type=${type}`,
            method: "DELETE",
        };
        const response = await makeRequest(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            if (!isWait) {
                ordersDataFetch()
            }
            return true;

        } else {
            toast({ type: "error", message: response.message });
            return false;
        }
    }
    const submitTradeWithAPI = async (tradeParam) => {
        if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1 || myProfile?.kycOffline?.identity?.status != 1)) {
            toast({ type: "error", message: "Please complete the kyc details" });
            navigate('/my/identification');
            return false;
        }
        const params = {
            url: `${Config.V3_API_URL}trade/submit-spot-trade`,
            method: "POST",
            body: { data: tradeParam }
        };
        const response = await makeRequestWithErrorMessage(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            setUserProfile();
            ordersDataFetch()
            return true;
        }
        else {
            toast({ type: "error", message: response.message });
            return false;
        }
    }
    const updateFavPairs = async (name, dataSingle) => {
        const params = {
            url: `${Config.V1_API_URL}user/updateSpotFav`,
            method: "POST",
            body: { data: name }
        };
        const response = await makeRequest(params);

        if (response.status) {
            if (response?.isAdd === "1") {

                setFavs((data) => [...data, dataSingle])
            }
            else if (response?.isAdd === "0") {
                setFavs((data) => {
                    const cutData = data.filter(data => data?.symbol !== dataSingle?.symbol)
                    // [...data,dataSingle]
                    return cutData
                })
            }
            // toast({ type: "success", message: response.message });
        }
        else {
            // toast({ type: "error", message: response.message });
        }
    }

    const fromSymbolName = (symbol, type = 0) => {
        if (!symbol || symbol == '') return null;
        type = parseFloat(type);
        let verify = symbol ? symbol.split('-')[type] : null
        return verify;
    }
    const handleSelectedPriceOrderBook = (prc) => {
        if (!prc) return null;
        const coinPrice = +prc;
        setPriceStatic(() => coinPrice);
        const total = (coinPrice * amount);
        setQuantity(() => total == 0 || !total || total == null ? null : total.toFixed(pairDetails?.quoteDecimal))
    }

    const myOrdersDiv = <div className="">
        <SpotMyOrdersUsdt myProfile={myProfile} trades={trades} symbolsData={symbolsData} countDecimals={countDecimals} cancelOrder={cancelOrder} setCurrentPage_tradeHis={setCurrentPage_tradeHis} currentPage_tradeHis={currentPage_tradeHis} decimalValue={decimalValue} pairsLoader={pairsLoader} orderFetchLoading={orderFetchLoading} ordersDataFetch={ordersDataFetch} openOrdersDataFetch={openOrdersDataFetch} currentPage={currentPage} totalPages={totalPages} orderHistoryDataFetch={orderHistoryDataFetch} stopOrderDataFetch={stopOrderDataFetch} fillsOrderDataFetch={fillsOrderDataFetch} />
    </div>
    const orderBooksDiv = <div className="col-12 col-lg-4 col-xxl-3 h-100">
        <div className="pt-2">
            <div className="d-flex flex-column trading-page-top-section-2 align-items-center top-heading-total-height">
                <div className="me-auto ps-2 menu-option-part-order w-100" style={{ gap: '10px' }}>
                    <Typography component='h1' className={`mb-0 pt-1 pb-2 menu-option-part-order-p  ${tradesShow == 0 ? 'text-active-order' : ''}`} onClick={() => setTradesShow(() => 0)}>Order Book</Typography>
                    <Typography component='h1' className={`mb-0 pt-1 pb-2 menu-option-part-order-p  ${tradesShow == 1 ? 'text-active-order' : ''}`} onClick={() => setTradesShow(() => 1)}>Real-Time Trades</Typography>
                </div>
            </div>
            {tradesShow == 0 ?
                <div className='p-0 futures-trading-table-total-height'>
                    <div className="text-end d-flex justify-content-end align-items-center orderbook-grid-tabs mt-1">
                        <ul className="nav nav-pills" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('buy')}>
                                <button className="nav-link active" id="pills-grid1-tab" data-bs-toggle="pill" data-bs-target="#pills-grid1" type="button" role="tab" aria-controls="pills-grid1" aria-selected="true">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_9" data-name="Rectangle 9" fill="none" stroke="#20b958" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(8 5) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(8 12) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('sell')}>
                                <button className="nav-link" id="pills-grid2-tab" data-bs-toggle="pill" data-bs-target="#pills-grid2" type="button" role="tab" aria-controls="pills-grid2" aria-selected="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_9" data-name="Rectangle 9" fill="none" stroke="#ff5a5a" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_10" data-name="Rectangle 10" transform="translate(8 5) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                        <g id="Rectangle_11" data-name="Rectangle 11" transform="translate(8 12) rotate(-90)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="12" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="11" fill="none" />
                                        </g>
                                    </svg>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation" onClick={() => setOrderListType('all')}>
                                <button className="nav-link" id="pills-grid3-tab" data-bs-toggle="pill" data-bs-target="#pills-grid3" type="button" role="tab" aria-controls="pills-grid3" aria-selected="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12">
                                        <g id="Rectangle_5" data-name="Rectangle 5" transform="translate(8)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="12" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="11" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_7" data-name="Rectangle 7" transform="translate(8 7)" fill="none" stroke="#b3b7c2" strokeLinecap="round" strokeWidth="1">
                                            <rect width="12" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="11" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_6" data-name="Rectangle 6" transform="translate(0 7)" fill="none" stroke="#20b958" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="4" fill="none" />
                                        </g>
                                        <g id="Rectangle_8" data-name="Rectangle 8" fill="none" stroke="#ff5a5a" strokeLinecap="round" strokeWidth="1">
                                            <rect width="5" height="5" rx="0.5" stroke="none" />
                                            <rect x="0.5" y="0.5" width="4" height="4" fill="none" />
                                        </g>
                                    </svg>

                                </button>
                            </li>
                        </ul>
                    </div>
                    {
                        pairsLoader ? <div className='trading-page-top-section-3 '>
                            <div className='' style={{ height: '480px' }}>
                                <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                    <div className="progress-table-1"><span className='orderbook-text-1'>Price(USDT)</span></div>
                                    <div className="progress-table-2"><span className='orderbook-text-1'>Amount</span></div>
                                    <div className="progress-table-3"><span className='orderbook-text-1'>Total</span></div>
                                </div>
                                <div className="h-100" style={{ marginTop: '-25px' }}><LoaderSpin /></div>

                            </div>
                        </div> :
                            <div>
                                <div className='trading-page-top-section-3 '>
                                    <div className='trading-table-left-section progress-bar-bg-color'>
                                        <div className="d-flex flex-row mb-1 mobile-justify-content orderbook-table-heading-section border-top-0">
                                            <div className="progress-table-1"><span className='orderbook-text-1'>Price(USDT)</span></div>
                                            <div className="progress-table-2"><span className='orderbook-text-1'>Amount</span></div>
                                            <div className="progress-table-3"><span className='orderbook-text-1'>Total</span></div>
                                        </div>
                                        <SpotOrderBookUsdt handleSelectedPriceOrderBook={handleSelectedPriceOrderBook} viewOrderList={orderBookData
                                            ?
                                            (orderListType === "all" || orderListType === "sell")
                                                ?
                                                orderBookData?.asks ? orderBookData?.asks?.slice(0, orderListType === "all" ? 8 : 16).reverse() : []
                                                :
                                                orderBookData?.bids ? orderBookData?.bids : []
                                            :
                                            []} orderListType={(orderListType === "all" || orderListType === "sell") ? "sell" : "buy"}
                                            viewLimit={orderListType === "all" ? 8 : 16}
                                            pairDetails={null}
                                            reverse={"yes"}
                                        />

                                    </div>
                                </div>
                                {orderListType == "all" &&
                                    <div className="d-flex flex-row orderbook-table-heading-section">
                                        {pairDetails ? <div className="ps-2">
                                            <span className={`${pairDetails?.data?.price >= pairDetails?.prevPrice ? 'color-green-futures' : 'color-red-futures'} fw-bold`}>
                                                {pairDetails?.data?.price}
                                            </span>
                                        </div> : ""}
                                    </div>
                                }
                                {orderListType == "all" &&
                                    <div className='trading-table-left-section progress-bar-bg-color '>
                                        <SpotOrderBookUsdt
                                            orderListType={"buy"}
                                            viewOrderList={orderBookData?.bids}
                                            viewLimit={8}
                                            pairDetails={null}
                                            reverse={"no"}
                                            handleSelectedPriceOrderBook={handleSelectedPriceOrderBook}
                                        />
                                    </div>
                                }
                            </div>
                    }
                </div>
                :
                <SpotRecentUsdt
                    priceObj={priceObj}
                    tradeHistory={recentTradesData}
                    pairsLoader={pairsLoader}
                    formatWithK={formatWithK}
                />
            }
        </div>
    </div>
    const createSubAccount = async (email) => {
        const params = {
            url: `${Config.V1_API_URL}user/create-sub-account`,
            method: "POST",
            body: { email: email }
        };
        const response = await makeRequestWithErrorMessage(params);
        if (response.status) {
            toast({ type: "success", message: response.message });
            setUserProfile();
            setSpotUsdtBalance()
        }
        else {
            toast({ type: "error", message: response.message });
        }
    }
    return (
        <div className='bg-default text-white pb-0 overflow-hidden min-vh-100'>
            <NavbarOne setTheme={props.setTheme} theme={props.theme} />
            <Helmet>
                <link rel="canonical" href="https://uniticexchange.com/futures/trade/XBTUSDTM" />
            </Helmet>
            <div className='container-fluid pt-2 setFontRoboto'>
                <div className="first-panel-futures">
                    <div className="first-div-futures">
                        <div className="marquee-container">

                            <div className={`futures-trade-top-section d-flex justify-content-start align-items-center overflow-auto ${isOverflowingSecond ? '' : ''}`} style={{ gap: '15px', whiteSpace: 'nowrap' }} ref={containerSecondRef}>
                                <div>
                                    <SpotMenuUsdt handleNavigation={handleNavigation} pairsData={pairsData} pairsLoader={pairsLoader} pairName={pairName} setOrderBookData={setOrderBookData} updateFavPairs={updateFavPairs} pairDetails={pairDetails} favs={favs} countDecimals={countDecimals} />
                                </div>
                                <div>
                                    <p className={`${pairDetails?.data?.price >= pairDetails?.prevPrice ? 'color-green-futures' : 'color-red-futures'} fw-bold`} style={{ fontSize: '1rem' }}>{pairDetails.data?.price}</p>
                                    {/* <p className={`color-green-futures fw-bold`} style={{ fontSize: '1rem' }}>{pair?.last}</p> */}
                                </div>
                                <div className="rate-part">
                                    <p>24 High</p>
                                    <p>{pairDetails?.market?.high}</p>
                                </div>
                                <div className="rate-part">
                                    <p>24h Low</p>
                                    <p>{pairDetails?.market?.low}</p>
                                </div>
                                <div className="rate-part">
                                    <p>24h Amount ({pairDetails?.data?.baseCurrency})</p>
                                    <p>{formatNumber(pairDetails?.market?.vol)}</p>
                                </div>
                                <div className="rate-part">
                                    <p>24 Volume ({pairDetails?.data?.quoteCurrency})</p>
                                    <p>{formatNumber(pairDetails?.market?.volValue)}</p>
                                </div>

                            </div>
                            <div className={`futures-trade-top-section futures-first-part ${isOverflowing ? 'marquee-content' : ''}`} ref={containerRef} style={{ display: (!favs) ? 'none' : '' }}>
                                {
                                    favs && favs.map((dataSingle, index) =>
                                        <p key={index}><span onClick={() => handleNavigation(dataSingle?.symbol)} className="cursor-pointer">{showPairNameUsdt(dataSingle?.symbol)}</span> <span className={`${(+dataSingle?.last) <= (+dataSingle?.averagePrice) ? 'color-green-futures' : 'color-red-futures'} `}>{dataSingle?.last}</span></p>
                                    )

                                }
                            </div>
                            {/* <div className="futures-trade-top-section option-part">

                                <div onClick={() => setFirstOptionPart(0)}>
                                    <h6 className={`${firstOptionPart !== 0 ? 'futures-secondary' : ''} cursor-pointer`}>Chart</h6>
                                </div>
                                <div onClick={() => setFirstOptionPart(1)}>
                                    <h6 className={`${firstOptionPart !== 1 ? 'futures-secondary' : ''} cursor-pointer`}>Info</h6>
                                </div>
                                <div onClick={() => setFirstOptionPart(2)}>
                                    <h6 className={`${firstOptionPart !== 2 ? 'futures-secondary' : ''} cursor-pointer`}>Trading Data</h6>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-8 col-xxl-9">
                                <div >
                                    {firstOptionPart === 0 &&
                                        <div id="tradingviewwidgetFutures">
                                            {(pairName && !pairsLoader) ? <TradingViewSpot
                                                symbol={pairName}
                                                theme={props.theme}
                                                dec={priceObj?.priceDecimal}
                                            /> : <LoaderUnitic />
                                            }
                                        </div>
                                    }
                                    {firstOptionPart === 1 ? <SpotInfoUsdt /> : firstOptionPart === 2 ? <SpotTradingDataUsdt /> : null}
                                </div>
                                {/* {window.innerWidth > 991 ? myOrdersDiv : orderBooksDiv} */}
                            </div>
                            {orderBooksDiv}
                        </div>
                        <div>
                            {myOrdersDiv}
                        </div>
                    </div>
                    <div className="second-div-futures" style={{}}>
                        <div style={{ height: '42px' }}></div>
                        <SpotTradeFormUsdt myProfile={myProfile} createSubAccount={createSubAccount} pairDetails={pairDetails} priceStatic={priceStatic} setPriceStatic={setPriceStatic} walletData={walletData} submitTrade={submitTradeWithAPI}
                            spotBalance={spotBalance}
                            fromSymbolName={fromSymbolName}
                            handleClickOpenOrderConfirmation={handleClickOpenOrderConfirmation}
                            amount={amount} setAmount={setAmount}
                            quantity={quantity} setQuantity={setQuantity}
                        />
                    </div>
                </div>
                {/* <div>
                    {myOrdersDiv}
                </div> */}
            </div>
            {openOrderConfirmation && <OrderConfirmationUsdt open={openOrderConfirmation} handleClickOpen={handleClickOpenOrderConfirmation} handleClose={handleCloseOrderConfirmation} tradeParams={tradeParams} submitTrade={submitTradeWithAPI} amount={amount} setAmount={setAmount} quantity={quantity} setQuantity={setQuantity} />}
            {openModifyOrder && <ModifyOrderUsdt open={openModifyOrder} handleClickOpen={handleClickOpenModifyOrder} handleClose={handleCloseModifyOrder} />}
            {openModifyMargin && <ModifyMarginUsdt open={openModifyMargin} handleClickOpen={handleClickOpenModifyMargin} handleClose={handleCloseModifyMargin} />}
            {openAdjust && <AdjustLeverageUsdt open={openAdjust} handleClickOpen={handleClickOpenAdjust} handleClose={handleCloseAdjust} xMenu={xMenu} setXMenu={setXMenu} pairDetails={pairDetails} pairName={pairName} formatSymbolName={formatSymbolName} />}
            {openMarginMode && <MarginModeUsdt open={openMarginMode} handleClickOpen={handleClickOpenMarginMode} handleClose={handleCloseMarginMode} crossMenu={crossMenu} setCrossMenu={setCrossMenu} />}
        </div>
    );
};

export default SpotTradeUsdt;