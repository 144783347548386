import Config from "../config/";
import { makeRequest, makeRequestWihActualStatus } from "../services/v1/request";

export async function getSiteSettings() {
  const params = { 
    url: `${Config.V1_API_URL}common/siteSettings`,
    method: 'GET',
  }
  return (await makeRequestWihActualStatus(params));
}
export async function getP2PSettings() {
  const params = { 
    url: `${Config.V1_API_URL}admin/p2p/getP2PSettings`,
    method: 'GET',
  }
  return (await makeRequestWihActualStatus(params));
}

export async function getMyProfile() {
  const params = { 
    url: `${Config.V1_API_URL}user/getMyProfile`,
    method: 'GET',
  }
  return (await makeRequestWihActualStatus(params));
}

export async function getMyFuturesBalance() {
  const params = { 
    url: `${Config.V2_API_URL}trade-futures/get-futures-balance`,
    method: 'GET',
  }
  return (await makeRequestWihActualStatus(params));
}
export async function getMySpotUsdtBalance() {
  const params = { 
    url: `${Config.V3_API_URL}trade/get-spot-balanceV2`,
    method: 'GET',
  }
  return (await makeRequestWihActualStatus(params));
}