import React, { useEffect, useState } from "react";
import './Portfolio.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Form, InputGroup } from 'react-bootstrap';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import "../../assets/style.css";
import { RiArrowDownSFill } from "react-icons/ri";
import {
    useParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import NavbarOne from "../../Pages/separate/NavbarOne";
import Footer from "../../Pages/separate/Footer";
import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";
import { useContextData } from '../../core/context/index'
import { toast } from "../../core/lib/toastAlert";
import { dateFormat } from '../../core/helper/date-format';
import { shortAdrress } from '../../core/helper/short-address';
import { copyText } from '../../core/helper/common';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CountDown from "./CountDown";

const validationSchema = yup.object({
    amount: yup
        .number('Enter enter the amount')
        .required('Amount is required'),
    address: yup
        .string('Please select the address')
        .required('Address is required'),
});

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'center'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'

}));


function CustomOption({ innerProps, label, data }) {
    return (
        <div {...innerProps} className="custom-option">
            <img src={data.image} alt="" className="custom-option-image" />
            {label}
        </div>
    );
}
const BDXCurrencyId = "643531591490e7ffdbccea29";
const WithdrawCrypto = (props) => {
    const navigate = useNavigate();
    let { currencyId } = useParams();
    const { getSpotUsdtBalancePortfolio, spotBalPortfolio, myProfile } = useContextData();
    const [searchParams, setSearchParams] = useSearchParams();
    let walletType = searchParams.get("type");
    const [defaultCurrencyList, setdefaultCurrencyList] = useState({ currencySymbol: "", image: "" });
    const [defaultNetwork, setdefaultNetwork] = useState('');
    const [userbalance, setUserBalance] = useState(0);
    const [fees, setFeevalue] = useState(0);
    const [minwithamt, setminwithamt] = useState(0);
    const [currencyList, setCurrencyList] = useState({});
    const [currencyID, setCurrencyID] = useState(currencyId);
    const [particularcurrency, set_particularCurrency] = useState([]);
    const [networkName, setNetworkName] = useState("");
    const [withdrawshow, setWithdrawshow] = useState(0);
    const [withdrawcryptoList, setWithdrawCryptoList] = useState([]);
    const [networkList, setnetworkList] = useState([]);
    const [networkId, setnetworkId] = useState("");
    const [withdrawStatus, setwithdrawStatus] = useState(null);
    const [currencies, setCurrencies] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [passData, setpassData] = useState({});
    const [inrCurrencyList, setinrCurrencyList] = useState({});
    const updateQuery = (el) => {
        setSearchParams({ type: el });
    };
    useEffect(() => {
        if (!walletType || (walletType !== 'USDT' && walletType !== 'INR')) {
            updateQuery('INR')
        }
    }, [walletType])
    useEffect(() => {
        if (myProfile && myProfile.email == "") {
            toast({ type: "error", message: "Please enable email verification" });
            navigate('/my/email-authenticator');
        }
        if (myProfile && myProfile.tfaenablekey == "") {
            toast({ type: "error", message: "Please completed 2FA to process withdraw." });
            navigate('/google-authenticator');
        }
        if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1||myProfile?.kycOffline?.identity?.status != 1)) {
            toast({ type: "error", message: "Please complete the kyc details" });
            navigate('/my/identification');
        }
        if (myProfile && myProfile.email) {
            getWalletCurrency_func();
            getinrCurrency();
            getSpotUsdtBalancePortfolio();
        }
    }, [myProfile]);

    async function getWalletCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status && response.data) {
                let currencyArray = [];
                let currencySymbol = '';
                let Network = '';
                let defaultNetwork = '';
                let id = '';
                let image = '';
                let NetworkId = '';
                response.data.map((item) => {
                    const findOne = response.data.find(element => element._id == currencyId);
                    if (findOne.curnType == "Fiat") {
                        id = response.data[0]._id
                    } else {
                        id = currencyID
                    }
                    setCurrencyID(id);
                    if (item._id == id) {
                        setnetworkId(item.currencyId);
                        NetworkId = item.currencyId;
                        currencySymbol = item.currencySymbol;
                        image = item.image;
                        item.ids.map((item) => {
                            Network = item.basecoin;
                            defaultNetwork = item._id;
                            if (item.withdrawEnable == 0) {
                                setwithdrawStatus(false)
                            }
                            if (item.withdrawEnable == 1) {
                                setwithdrawStatus(true)
                            }
                        })
                        setnetworkList(item.ids);
                    }
                    currencyArray.push({ value: item._id, label: item.currencySymbol, image: item.image, currencyName: item.currencyName, balance: item.balance });
                })
                setdefaultNetwork(defaultNetwork);
                setNetworkName(Network);
                setCurrencyList(currencyArray);
                setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image })

                getparticularHistory(currencySymbol)
                getParticularCurrency(defaultNetwork);
                getBalance(NetworkId);
                getFee(defaultNetwork);
            }
        } catch (err) { }
    }

    async function getParticularCurrency(defaultNetwork) {
        try {
            const value = { CurrencyID: defaultNetwork }
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setCurrencies(response.data);
                set_particularCurrency(response.data);
                setNetworkName(response.data.basecoin);
            }
        } catch (err) { }
    }

    async function getparticularHistory(Currency) {
        const value = { type: "Withdraw", currencyType: "Crypto", currencySymbol: Currency }
        const params = {
            url: `${Config.V1_API_URL}wallet/getHistory`,
            method: 'POST',
            body: value
        }
        const result = (await makeRequest(params));
        if (result.status) {
            const array = []
            result.data.map((item) => {
                if (Currency == item.currencyId.currencySymbol) {
                    array.push({
                        address: item.address,
                        currencySymbol: item.currencyId.currencySymbol,
                        image: item.currencyId.image,
                        amount: item.amount,
                        fees: item.fees,
                        txnId: item.txnId,
                        status: item.status,
                        createdDate: item.createdDate
                    })
                }
            })
            setWithdrawCryptoList(array)
        }
    }

    async function getFee(defaultNetwork) {
        const reqData = { CurrencyID: defaultNetwork }
        const params = {
            url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
            method: 'POST',
            body: reqData
        }
        const res = (await makeRequest(params));
        if (res.status) {
            setCurrencies(res.data);
            if (typeof res.data.withdrawLevel == 'object' && typeof res.data.withdrawLevel["level" + myProfile?.level] == 'object') {
                const data = res.data.withdrawLevel["level" + myProfile?.level];
                setFeevalue(data.fees);
                setminwithamt(data.minwithamt);
                // console.log("res", res.data) 
            }
        }
    }
    async function getBalance(currencyId) {
        if (currencyId != '') {
            const value = { currencyId: currencyId }
            const params = {
                url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                method: 'POST',
                body: value
            }
            const result = (await makeRequest(params));
            if (result.status) {
                var amount = result.data.amount;
                setUserBalance(amount)
            }
        }
    }
    async function handleCurrencyChange(currencyId, curr = null) {
        const type = walletType === 'USDT' ? 'USDT' : 'INR'
        if (walletType === 'USDT' || curr === 'USDT') {
            setCurrencyID(() => BDXCurrencyId)
            getSelectedCurrency(BDXCurrencyId)

            window.history.pushState({}, '', Config?.FRONEND_URL + "withdraw/crypto/" + BDXCurrencyId + "?type=USDT");
            getparticularHistory(defaultCurrencyList && defaultCurrencyList.currencySymbol);
            return;
        }
        setCurrencyID(() => currencyId)
        getSelectedCurrency(currencyId)

        window.history.pushState({}, '', Config?.FRONEND_URL + "withdraw/crypto/" + currencyId + "?type=INR");
        getparticularHistory(defaultCurrencyList && defaultCurrencyList.currencySymbol);
    };
    async function getSelectedCurrency(currencyValue) {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getWalletCurrency`,
                method: 'GET'
            }
            const response = (await makeRequest(params));
            if (response.status && response.data) {
                let currencyArray = [];
                let currencySymbol = '';
                let Network = '';
                let NetworkId = networkId;
                let defaultNetwork = '';
                let image = '';
                response.data.map((item) => {
                    if (item._id == currencyValue) {
                        setnetworkId(item.currencyId);
                        NetworkId = item.currencyId;
                        currencyId = item._id;
                        currencySymbol = item.currencySymbol;
                        image = item.image;
                        item.ids.map((item) => {
                            Network = item.basecoin;
                            defaultNetwork = item._id;
                            if (item.withdrawEnable == 0) {
                                setwithdrawStatus(false)
                            }
                            if (item.withdrawEnable == 1) {
                                setwithdrawStatus(true)
                            }
                        })
                        setnetworkList(item.ids)
                    }
                    currencyArray.push({ value: item._id, label: item.currencySymbol, image: item.image, currencyName: item.currencyName, balance: item.balance });
                })
                setdefaultNetwork(defaultNetwork);
                setNetworkName(Network);
                setdefaultCurrencyList({ currencySymbol: currencySymbol, image: image })
                getparticularHistory(currencySymbol);
                getBalance(NetworkId);
                getFee(defaultNetwork);
            }
        } catch (err) { }
    }
    async function handlenetworkChange(networkId) {
        setnetworkId(() => networkId);
        getselectedNetwork(networkId);
        getFee(networkId);
    }
    async function getselectedNetwork(networkId) {
        try {
            const value = { CurrencyID: networkId }
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                let type = "error";
                if (response.data.withdrawEnable == 0) {
                    setwithdrawStatus(false)
                } else {
                    setwithdrawStatus(true);
                }
                setNetworkName(response.data.basecoin);
                setCurrencies(response.data);
            }
        } catch (err) { }
    }
    async function getinrCurrency() {
        try {
            const value = { CurrencyID: "" }
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setinrCurrencyList(response.data);
            }
        } catch (err) { }
    }

    const handleChange = (event) => {
        const { name = "", value, checked } = event.target;
        switch (name) {
            case "address":
            case "amount":
                passData[name] = value;
                setpassData(passData);
                break;
            default:
                break;
        }
    }

    const submitWithdraw = async (values = {}, target = "submit") => {
        let type = 'error';
        let balanceCheck = walletType === 'USDT' ? spotBalPortfolio?.loader ? 'Loading...' : spotBalPortfolio?.data?.find(el => el?.currency === 'BDX')?.available : userbalance
        if (values.amount <= balanceCheck) {
            let data = {
                currencyId: currencyID,
                amount: values.amount,
                address: values.address,
                tag: "",
                basecoin: networkName,
                type:walletType
            }
            if (currencies && currencies.currencySymbol == "XRP") {
                data.tag = values.tag;
                if (values.tag == "") {
                    formik.errors.tag = "Tag is required";
                    // toast({ type: "eroor", message: "Tag is required"});
                    return false
                }
            }

            if (withdrawshow != 0 && target !== "resendOTP") {
                data.withdrawOTP = values.withdrawOTP ? values.withdrawOTP : "-";
                data.tfaCode = values.tfaCode;
            }

            const params = {
                url: `${Config.V1_API_URL}wallet/submitWithdraw`,
                method: 'POST',
                body: data
            }
            setisLoading(true);
            const response = (await makeRequest(params));
            if (response.status) {
                setisLoading(false);
                type = 'success'
                setWithdrawshow(response.type)
                if (response.type == 3) {
                    formik.resetForm();
                    setWithdrawshow(0)
                    getparticularHistory(defaultCurrencyList && defaultCurrencyList.currencySymbol);
                    getBalance(networkId);
                    getSpotUsdtBalancePortfolio();
                }
            } else {
                setisLoading(false);
                const errmsg = response.message.split(' ');
                if (response.type == 4 && errmsg[2] == 'TFA') {
                    navigate('/my/google-authenticator')
                } else if (response.type == 4 && errmsg[2] == 'KYC') {
                    navigate('/my/identification')
                } else if (response.type == 4 && errmsg[2] == 'BANK') {
                    navigate('/my/payment')
                }
            }
            toast({ type, message: response.message });
        }
        else {      
            setisLoading(false);
            toast({ type, message: 'Insufficient Balance on ' + currencies.currencySymbol });
        }
    }

    const formik = useFormik({
        initialValues: {
            amount: '',
            address: '',
            tag: '',
            withdrawOTP: '',
            tfaCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setisLoading(true)
            submitWithdraw(values)
        },
    });

    function bonusAmount(trxn) {
        try {
            if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
                return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
            } else {
                if (trxn.depositType == 'Pre Booking') {
                    return '-';
                } else {
                    return 'NA';
                }
            }
        } catch (err) { }
    }
    const handleSearch = (e) => {
        const searchval = e.target.value;
        if (searchval !== '') {
            let results = []
            results = currencyList.filter((item) => {
                return (item.currencyName.toLowerCase().indexOf(searchval.toLowerCase()) >= 0 || item.currencyName.toLowerCase().indexOf(searchval.toLowerCase()) >= 0)
            });
            if (results.length > 0) {
                setCurrencyList(results);
            } else {
                setCurrencyList([]);
            }
        } else {
            getWalletCurrency_func();
        }
    }
    return (
        <section >
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='min-vh-100 bg-default text-light'>
                <div className='container pb-5 pt-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/my/dashboard")}><strong><ArrowBackIcon style={{ cursor: "pointer" }} /> Withdraw Crypto</strong></h4>
                        <h5 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/withdraw/fiat/" + inrCurrencyList._id)}><strong>Withdraw Fiat <ArrowForwardIcon style={{ cursor: "pointer" }} /></strong></h5>

                    </div>

                    <div className='withdraw-box mx-auto my-5 pb-4'>
                        <h5 className='text-center py-4 fw-bold'>
                            Crypto Withdraw
                        </h5>
                        {withdrawshow == 0 &&
                            <div className="w-75  mx-auto">
                                <Form.Group className="mb-3" data-bs-theme="dark">
                                    {/* <Form.Label className="text-light">Select Wallet</Form.Label> */}
                                    <Form.Select
                                        aria-label="wallet"
                                        className="deposit-select-coin-section custom-dropdown-wallet bg-transparent text-light"
                                        value={walletType} onChange={(e) => {
                                            updateQuery(e.target.value)
                                            if (e.target.value === 'USDT') {
                                                handleCurrencyChange(BDXCurrencyId, 'USDT')
                                            }
                                        }}
                                    >
                                        <option hidden>Select Wallet</option>
                                        <option value="INR">Spot INR</option>
                                        <option value="USDT">Spot USDT</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput00">
                                    <Form.Label >Select coin</Form.Label>
                                    <div
                                        class="d-flex flex-row align-items-center deposit-select-coin-section"
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectcoinModal"
                                    >
                                        <div class="">
                                            <img
                                                src={defaultCurrencyList && defaultCurrencyList.image}
                                                className="deposit-logo-image"
                                                alt="logo"
                                            />
                                            <span className="deposit-text-4">{defaultCurrencyList && defaultCurrencyList.currencySymbol}</span>
                                        </div>
                                        <div class="ms-auto">
                                            <RiArrowDownSFill />
                                        </div>

                                    </div>
                                </Form.Group>

                            </div>
                        }
                        <form onSubmit={formik.handleSubmit}>
                            {withdrawshow == 0 ?
                                <div>
                                    <Form className='w-75  mx-auto'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1454">
                                            <Form.Label >Network</Form.Label>
                                            <div
                                                class="d-flex flex-row align-items-center deposit-select-coin-section"
                                                data-bs-toggle="modal"
                                                data-bs-target="#withdrawnetworkModal"
                                            >
                                                <div class="">
                                                    <span className="deposit-text-4"></span>
                                                    <span className="deposit-text-5 text-light">{
                                                        networkName == "Coin"
                                                            ?
                                                            defaultCurrencyList.currencySymbol
                                                            :
                                                            networkName
                                                    }</span>
                                                </div>
                                                <div class="ms-auto">
                                                    <RiArrowDownSFill />
                                                </div>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput144">
                                            <Form.Label >Send To Address</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Address"
                                                id="address"
                                                name='address'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.address && Boolean(formik.errors.address)}
                                                helperText={formik.touched.address && formik.errors.address}
                                                value={formik.values.address} className='payment-withdraw' />
                                        </Form.Group>
                                        {formik.errors.address ? <small className="invalid-address error">{formik.errors.address}</small> : null}
                                        {currencies && currencies.currencySymbol == "XRP" ? <><Form.Group className="mb-3" controlId="exampleForm.ControlInput178892">
                                            <Form.Label>Tag</Form.Label>
                                            <Form.Control type="text" placeholder="Destination TAG"
                                                id="tag"
                                                name='tag'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.tag && Boolean(formik.errors.tag)}
                                                helperText={formik.touched.tag && formik.errors.tag}
                                                value={formik.values.tag} className='payment-withdraw' />
                                        </Form.Group>
                                            {formik.errors.tag ? <small className="invalid-tag error">{formik.errors.tag}</small> : null} </> : null}

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput12124">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" placeholder={"Minimal " + currencies?.minwithamt + " " + (defaultCurrencyList && defaultCurrencyList.currencySymbol)}
                                                name="amount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                helperText={formik.touched.amount && formik.errors.amount}
                                                value={formik.values.amount} className='payment-withdraw' />
                                        </Form.Group>
                                        {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                                    </Form>

                                    <div className='mt-4 d-flex justify-content-between align-items-center w-75 mx-auto'>
                                        <div>
                                            <p style={{
                                                color: '#FFF',
                                                fontSize: '20px',
                                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                                fontWeight: '500'
                                            }}>{defaultCurrencyList && defaultCurrencyList.currencySymbol} balance</p>
                                            <p style={{
                                                color: '#C8C8C8',
                                                fontSize: '14px',
                                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                            }}><small>{walletType === 'USDT' ? spotBalPortfolio?.loader ? 'Loading...' : spotBalPortfolio?.data?.find(el => el?.currency === 'BDX')?.available : userbalance?.toFixed(currencies?.siteDecimal)} {defaultCurrencyList && defaultCurrencyList.currencySymbol}</small></p>
                                        </div>
                                        <div className='text-end'>
                                            <p style={{
                                                color: '#FFF',
                                                fontSize: '20px',
                                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                                fontWeight: '500'
                                            }}>Minimum Withdraw</p>
                                            <p style={{
                                                color: '#C8C8C8',
                                                fontSize: '14px',
                                                fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                            }}><small>{currencies?.minwithamt} {defaultCurrencyList && defaultCurrencyList.currencySymbol}</small></p>
                                        </div>
                                    </div>
                                    <div className='w-75 mx-auto my-3'>
                                        <p style={{
                                            color: '#FFF',
                                            fontSize: '20px',
                                            fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                            fontWeight: '500'
                                        }}>Network Fee</p>
                                        <p style={{
                                            color: '#C8C8C8',
                                            fontSize: '14px',
                                            fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                        }}><small>{currencies?.fees} {defaultCurrencyList && defaultCurrencyList.currencySymbol}</small></p>
                                    </div>
                                    <p style={{
                                        color: '#C8C8C8',
                                        fontSize: '14px',
                                        fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                    }} className='w-75 mx-auto'>{particularcurrency.withdrawNotes}</p>
                                    <div className='mx-auto text-center mt-3'>
                                        <button className='btn-profile shadow px-5' style={{ width: 'auto' }} type="submit"
                                            disabled={isLoading}>   {isLoading ? "Loading..." : "Withdraw"}</button>
                                    </div>

                                </div>

                                :
                                <>

                                    <Form className="w-75 mx-auto">
                                        <Form.Label>E-Mail OTP</Form.Label>
                                        <InputGroup className="mb-3" >
                                            <Form.Control type="text"
                                                aria-describedby="button-addon2"
                                                placeholder="Enter Email OTP"
                                                id="withdrawOTP"
                                                name='withdrawOTP'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.withdrawOTP && Boolean(formik.errors.withdrawOTP)}
                                                helperText={formik.touched.withdrawOTP && formik.errors.withdrawOTP}
                                                required className='payment-withdraw' />
                                            <InputGroup.Text id="basic-addon2" className='btn-profile fw-bold' style={{ width: 'auto', border: 'none', color: 'white', height: 'auto' }}
                                                onClick={() => submitWithdraw(formik.values, "resendOTP")}> Resend OTP</InputGroup.Text>
                                        </InputGroup>

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput45411">
                                            <Form.Label>Enter 2FA Code</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Enter 2FA Code"
                                                id="tfaCode"
                                                name="tfaCode"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.tfaCode && Boolean(formik.errors.tfaCode)}
                                                helperText={formik.touched.tfaCode && formik.errors.tfaCode}
                                                required className='payment-withdraw' />
                                        </Form.Group>
                                    </Form>
                                    <div className='mx-auto text-center mt-3'>


                                        <button
                                            className='btn-profile shadow  mx-2 my-2' style={{ width: 'auto', paddingLeft: '20px', paddingRight: '20px' }} type="submit"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Loading..." : "Withdraw"}
                                        </button>
                                        <button
                                            type="button"
                                            className='btn-profile shadow mx-2 my-2' style={{ width: 'auto', paddingLeft: '24px', paddingRight: '24px' }}
                                            onClick={() => setWithdrawshow(0)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            }
                        </form>
                    </div>

                    <div className='mt-5 pt-4'>
                        <h4 className='pb-4 ps-3 ps-md-5 fw-bold'>Recent Withdraw</h4>
                        <TableContainer>
                            <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                                <StyledTableHead>
                                    <TableRow>
                                        <StyledTableHeadRow>Assets</StyledTableHeadRow>
                                        <StyledTableHeadRow>Arriving_In</StyledTableHeadRow>
                                        <StyledTableHeadRow>Address</StyledTableHeadRow>
                                        <StyledTableHeadRow>Amount</StyledTableHeadRow>
                                        <StyledTableHeadRow>Fee</StyledTableHeadRow>
                                        <StyledTableHeadRow>Txn.id</StyledTableHeadRow>
                                        <StyledTableHeadRow>Status</StyledTableHeadRow>
                                        <StyledTableHeadRow>Date</StyledTableHeadRow>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>
                                    {withdrawcryptoList.length > 0 && withdrawcryptoList.map((row, i) => {
                                        if (row) {
                                            return (
                                                <TableRow key={i}>
                                                    <StyledTableCell component="th" scope="row"><span className='tb-img'>
                                                        <img src={row?.image} alt="currencyImage" width="20px" height="20px" style={{ height: '20%', width: '20%' }} className="img-fluid img-res" />
                                                    </span> {row?.currencySymbol}</StyledTableCell>
                                                    <StyledTableCell><CountDown startTime={row.createdDate} status={row.status} /></StyledTableCell>
                                                    <StyledTableCell onClick={() => copyText({
                                                        text: row.address != '' ? row.address : '-',
                                                        message: "Address copied successfully!"
                                                    })}>{shortAdrress(row.address)}</StyledTableCell>
                                                    <StyledTableCell>{row.amount}</StyledTableCell>
                                                    <StyledTableCell>{row.fees}</StyledTableCell>
                                                    <StyledTableCell onClick={() => copyText({
                                                        text: row.txnId != '' ? row.txnId : '',
                                                        message: "Txn ID copied successfully"
                                                    })}>{shortAdrress(row.txnId != '' ? row.txnId : '-')}</StyledTableCell>
                                                    <StyledTableCell>{row.status == 1
                                                        ?
                                                        "Completed"
                                                        :
                                                        row.status == 0
                                                            ?
                                                            'Pending'
                                                            :
                                                            row.status == 2
                                                                ?
                                                                'Rejected'
                                                                :
                                                                'Processing'
                                                    }</StyledTableCell>
                                                    <StyledTableCell>{dateFormat(row.createdDate)}</StyledTableCell>
                                                </TableRow>
                                            )
                                        }
                                    })
                                    }

                                    {withdrawcryptoList.length == 0 &&

                                        <TableRow >
                                            <StyledTableCell component="th" scope="row"></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell><img
                                                className="no-record-image"
                                                src="/assets/no-re.png"
                                                alt="no-record"
                                            />
                                                <br />
                                                <span className="text-center">No Records Found</span></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div
                        class="modal fade withdraw-crypto-modal"
                        id="selectcoinModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header ">
                                    <h4 class="modal-title" id="exampleModalLabel">
                                        Select coin to withdraw
                                    </h4>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div class="modal-body modal-2-overflow-scroll">
                                    <input
                                        id="myInput2"
                                        type="text"
                                        placeholder="Search here..."
                                        autoComplete="off"
                                        onChange={handleSearch}
                                        className={walletType === 'USDT' ? 'd-none' : ''}
                                    />
                                    <ul className="withdraw-crypto-modal-list">
                                        {currencyList.length > 0 && currencyList.map((currency, i) => {
                                            if (currency.label != 'INR') {
                                                if (walletType === 'USDT' && currency.label !== 'BDX') {
                                                    return null;
                                                }
                                                return (
                                                    <li onClick={() => handleCurrencyChange(currency.value)}>
                                                        <a data-bs-dismiss="modal">
                                                            <div class="d-flex flex-row align-items-center withdraw-crypto-list-items">
                                                                <div class="">
                                                                    <img
                                                                        src={currency.image}
                                                                        alt="logo"
                                                                        className="withdraw-crypto-modal-images"
                                                                    />
                                                                </div>
                                                                <div class="">
                                                                    <span className="withdraw-crypto-modal-text-1">
                                                                        {currency.label}
                                                                    </span>
                                                                    <br />
                                                                    <span className="withdraw-crypto-modal-text-2">
                                                                        {currency.currencyName}
                                                                    </span>
                                                                </div>
                                                                <div class="ms-auto">
                                                                    <span className="withdraw-crypto-modal-text-3">
                                                                        Spot
                                                                    </span>
                                                                    <span className="withdraw-crypto-modal-text-4">
                                                                        {walletType === 'USDT' ? spotBalPortfolio?.loader ? 'Loading...' : spotBalPortfolio?.data?.find(el => el?.currency === 'BDX')?.available : currency.balance}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="modal fade withdraw-crypto-network-modal"
                        id="withdrawnetworkModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title" id="exampleModalLabel">
                                        Select network
                                    </h4>
                                    <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div class="modal-body modal-2-overflow-scroll">
                                    <p className="select-network-text-1">
                                        Please ensure your receiving platform supports the token and
                                        network you are withdrawing. If you are unsure, kindly check
                                        with the receiving platform first.
                                    </p>
                                    <ul className="withdraw-crypto-modal-list">
                                        {networkList.length > 0 && networkList.map((item, i) => {
                                            if (item._id == defaultNetwork) {
                                                return (
                                                    <li selected={true} onClick={() => handlenetworkChange(networkName == item.basecoin ? networkName : item._id)}>
                                                        <a data-bs-dismiss="modal">
                                                            <div className="d-flex flex-row withdraw-modal-network-sizing">
                                                                <div>
                                                                    <p className="deposit-modal-coin-heading">
                                                                        {networkName == item.basecoin
                                                                            ?
                                                                            networkName == "Coin"
                                                                                ?
                                                                                defaultCurrencyList.currencySymbol
                                                                                :
                                                                                networkName
                                                                            :
                                                                            item.basecoin}
                                                                    </p>
                                                                </div>
                                                                <div className="ms-auto text-end">
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li onClick={() => handlenetworkChange(networkName == item.basecoin ? networkName : item._id)}>
                                                        <a data-bs-dismiss="modal">
                                                            <div className="d-flex flex-row withdraw-modal-network-sizing">
                                                                <div>
                                                                    <p className="deposit-modal-coin-heading">{networkName == item.basecoin ? networkName : item.basecoin}</p>
                                                                    <p className="withdraw-modal-coin-muted-text">
                                                                    </p>
                                                                </div>
                                                                <div className="ms-auto text-end">

                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </section>
    );
};

export default WithdrawCrypto;