import React, { useEffect, useState } from "react";
import './Portfolio.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Form } from 'react-bootstrap';
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import { RiArrowDownSFill } from 'react-icons/ri';
import $ from "jquery";

import Config from "../../core/config/";
import { makeRequest } from "../../core/services/v1/request";

import NavbarOne from "../../Pages/separate/NavbarOne";
import Footer from "../../Pages/separate/Footer";

import { toast } from "../../core/lib/toastAlert";
import { dateFormat, shortAdrress } from '../../core/helper/date-format';
import { useContextData } from '../../core/context/index'
import * as yup from 'yup';
import { useFormik } from 'formik';

import "../../assets/style.css";

const validationSchema = yup.object({
    amount: yup
        .number('Enter enter the amount')
        .required('Balance is required'),
});

const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "600",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'center'
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'
}));


const WithdrawFlatV2 = (props) => {
    const navigate = useNavigate();
    const { myProfile } = useContextData();
    const { currencyId } = useParams();
    const [withdrawshow, setWithdrawshow] = useState(0);
    const [fee, setFeevalue] = useState(0);
    const [userbalance, setUserBalance] = useState(0);
    const [withdrawfiatList, setWithdrawFiatList] = useState({});
    const [particularCurrencyList, setparticularCurrencyList] = useState({});
    const [fiat, setFiat] = useState('');
    const [currencies, setCurrencies] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [bankpaymentsList, setbankpaymentsList] = useState([]);
    const [bankDetails, setbankDetails] = useState({});

    useEffect(() => {
        $(".crypto-workflow-section-close-button").click(function () {
            $(".deposit-second-section").hide();
        });
    }, []);

    useEffect(() => {
        getInrCurrency();
        getparticularCurrency_func();
        getHistory();
        getBankPaymentsDet();
    }, []);

    useEffect(() => {
        if (myProfile && myProfile.email == "") {
            toast({ type: "error", message: "Please enable email verification" });
            navigate('/my/email-authenticator');
        }
        if (myProfile && myProfile.tfaenablekey == "") {
            toast({ type: "error", message: "Please completed 2FA to process withdraw." });
            navigate('/google-authenticator');
        }
        if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1||myProfile?.kycOffline?.identity?.status != 1)) {
            toast({ type: "error", message: "Please complete the kyc details" });
            navigate('/my/identification');
        }
    }, [myProfile]);

    async function getInrCurrency() {
        try {
            const value = { currencyId: currencyId }
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setFiat(response.data?._id);
                setCurrencies(response.data);
                if (typeof response.data.withdrawLevel == 'object' && typeof response.data.withdrawLevel["level" + myProfile?.level] == 'object') {
                    const data = response.data.withdrawLevel["level" + myProfile?.level];
                    const fee = data.fees;
                    setFeevalue(fee)
                }
                const value = { currencyId: (response?.data?.currencyId) }
                const params = {
                    url: `${Config.V1_API_URL}wallet/getCurrencyBalance`,
                    method: 'POST',
                    body: value
                }
                const result = (await makeRequest(params));
                if (result.status) {
                    var amount = result.data.amount;
                    setUserBalance(amount)
                }
            }
        } catch (err) { }
    }
    async function getparticularCurrency_func() {
        try {
            const params = {
                url: `${Config.V1_API_URL}wallet/getParticularCurrency`,
                method: "POST",
                body: { currencySymbol: "BNB" }
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                setparticularCurrencyList(response.data);
            }
        } catch (err) { }
    }
    async function getHistory() {
        try {
            const value = { type: "Withdraw", currencyType: "Fiat" }
            const params = {
                url: `${Config.V1_API_URL}wallet/getHistory`,
                method: 'POST',
                body: value
            }
            const response = (await makeRequest(params));
            if (response.status) {
                setWithdrawFiatList(response.data)
            }
        } catch (err) { }
    }
    const formik = useFormik({
        initialValues: {
            amount: '',
            withdrawOTP: '',
            tfaCode: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setisLoading(true);
            let type = 'error';
            if (bankDetails == "" || bankDetails && bankDetails._id == undefined) {
                let type = "error";
                toast({ type, message: 'Please add bank details' });
                navigate('/my/payment')
                return false;
            }

            if (values.amount <= userbalance) {
                const data = {
                    currencyId: fiat,
                    amount: values.amount,
                    withdrawOTP: values.withdrawOTP,
                    tfaCode: values.tfaCode,
                    paymentId: bankDetails?._id,
                    type:'INR'
                }
                const params = {
                    url: `${Config.V1_API_URL}wallet/submitWithdraw`,
                    method: 'POST',
                    body: data
                }
                const response = (await makeRequest(params));
                if (response.status) {
                    type = 'success';
                    setWithdrawshow(response.type)
                    if (response.type == 3) {
                        formik.resetForm();
                        setWithdrawshow(0)
                        getHistory();
                    }
                } else {
                    const errmsg = response.message.split(' ');
                    if (response.type == 4 && errmsg[2] == 'TFA') {
                        navigate('/google-authenticator')
                    } else if (response.type == 4 && errmsg[2] == 'KYC') {
                        navigate('/my/identification')
                    } else if (response.type == 4 && errmsg[2] == 'BANK') {
                        navigate('/my/payment')
                    }
                }
                setisLoading(false);
                toast({ type, message: response.message });
            } else {
                toast({ type, message: 'Insufficient Balance on ' + currencies.currencySymbol });
            }
        },
    });
    function bonusAmount(trxn) {
        try {
            if (typeof trxn.bonusData == 'object' && trxn.bonusData.bonusGiven > 0) {
                return trxn.bonusData.bonusGiven.toFixed(trxn.currencyId.siteDecimal);
            } else {
                if (trxn.depositType == 'Pre Booking') {
                    return '-';
                } else {
                    return 'NA';
                }
            }
        } catch (err) { }
    }
    async function getBankPaymentsDet() {
        try {
            const params = {
                url: `${Config.V2_API_URL}customer/getBankPayments`,
                method: "GET",
            };
            const response = await makeRequest(params);
            if (response.status && response.data) {
                let bankDet = []
                response.data.length > 0 && response.data.map((data, i) => {
                    if (i == 0 && data.status == 1) {
                        setbankDetails(data);
                    }
                    if (data.status == 1) {
                        bankDet.push(data)
                    }
                })
                setbankpaymentsList(bankDet);
            }
        } catch (err) { }
    }
    async function handleBankChange(type) {
        setbankDetails(type);
    }
    return (
        <section >
            <NavbarOne
                setTheme={props.setTheme}
                theme={props.theme}
            />
            <div className='min-vh-100 bg-default text-light pb-5 pt-3'>
                <div className='container pb-5 pt-2'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/withdraw/crypto/" + particularCurrencyList?._id)}><strong><ArrowBackIcon style={{ cursor: "pointer" }} /> Withdraw Crypto</strong></h4>
                        <h5 className='mb-0 controlFont' style={{ cursor: 'pointer' }} onClick={() => navigate("/my/dashboard")}><strong>Withdraw Fiat <ArrowForwardIcon style={{ cursor: "pointer" }} /></strong></h5>

                    </div>

                    <div className='d-flex justify-content-between align-items-start mt-5 flex-column flex-md-row'>
                        <div className='withdraw-flat-box pb-5'>
                            <h5 className='text-center py-4 fw-bold'>
                                Fiat Withdraw
                            </h5>
                            <div>
                                {withdrawshow == 0 ?
                                    <> <form onSubmit={formik.handleSubmit}>
                                        <Form className='w-75  mx-auto'>

                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Account Number</Form.Label>
                                                <div
                                                    class="d-flex flex-row align-items-center deposit-select-coin-section"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#selectcoinModal"
                                                >
                                                    <div class="">
                                                        <span className="deposit-text-4">{bankDetails.accountNo}</span>
                                                    </div>
                                                    <div class="ms-auto">
                                                        <RiArrowDownSFill />
                                                    </div>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label >Enter Amount</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Amount"
                                                    id="amount"
                                                    name='amount'
                                                    autoComplete='off'
                                                    value={formik.values.amount}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                                                    helperText={formik.touched.amount && formik.errors.amount} className='payment-withdraw' />
                                            </Form.Group>
                                            {formik.errors.amount ? <small className="invalid-amount error">{formik.errors.amount}</small> : null}
                                        </Form>

                                        <div className='mt-4 d-flex justify-content-between align-items-center w-75 mx-auto'>
                                            <div>
                                                <p style={{
                                                    color: '#FFF',
                                                    fontSize: '18px',
                                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                                    fontWeight: '500'
                                                }}>Balance : {userbalance?.toFixed(currencies?.siteDecimal)} {currencies?.currencySymbol}</p>
                                            </div>
                                            <div className='text-end'>
                                                <p style={{
                                                    color: '#FFF',
                                                    fontSize: '18px',
                                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
                                                    fontWeight: '500'
                                                }}>Fees : {fee.toFixed(currencies?.siteDecimal)}</p>
                                            </div>
                                        </div>

                                        <div className='mx-auto text-center mt-3'>
                                            <button className='btn-profile shadow px-3' type="submit" style={{ width: 'auto' }} disabled={isLoading}>Submit Withdraw</button>
                                        </div>
                                    </form>
                                    </> : <form onSubmit={formik.handleSubmit}>
                                        <Form className="w-75 mx-auto">
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>E-Mail OTP</Form.Label>
                                                <Form.Control type="text" placeholder="E-Mail OTP"
                                                    id="withdrawOTP"
                                                    name='withdrawOTP'
                                                    autoComplete='off'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.withdrawOTP}
                                                    error={formik.touched.withdrawOTP && Boolean(formik.errors.withdrawOTP)}
                                                    helperText={formik.touched.withdrawOTP && formik.errors.withdrawOTP}
                                                    required className='payment-withdraw' />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Enter 2FA Code</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Amount"
                                                    id="tfaCode"
                                                    name='tfaCode'
                                                    autoComplete='off'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.tfaCode}
                                                    error={formik.touched.tfaCode && Boolean(formik.errors.tfaCode)}
                                                    helperText={formik.touched.tfaCode && formik.errors.tfaCode}
                                                    required className='payment-withdraw' />
                                            </Form.Group>
                                        </Form>
                                        <div className='mx-auto text-center mt-3'>
                                            <button className='btn-profile shadow px-3' type="submit" style={{ width: 'auto' }} disabled={isLoading}>Submit Withdraw</button>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                        <div
                            class="modal fade withdraw-crypto-modal"
                            id="selectcoinModal"
                            tabindex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div class="modal-content">
                                    <div class="modal-header ">
                                        <h4 class="modal-title" id="exampleModalLabel">
                                            Select Account Number
                                        </h4>
                                        <button
                                            type="button"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div class="modal-body modal-2-overflow-scroll">
                                        <ul className="withdraw-crypto-modal-list">
                                            {bankpaymentsList.length > 0 && bankpaymentsList.map((row, i) => {
                                                return (
                                                    <li key={i} onClick={() => handleBankChange(row)}>
                                                        <a data-bs-dismiss="modal">
                                                            <div class="d-flex flex-row align-items-center withdraw-crypto-list-items">
                                                                <div class="">
                                                                    <span className="withdraw-crypto-modal-text-1">
                                                                        {row.bankName} - {row.accountNo}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='width-text mt-5 mt-md-0'>
                            <h3>The amount would be transferred to</h3>
                            {bankDetails && <>
                                <p style={{
                                    color: '#C8C8C8',
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                                }}>Fiat Withdraw can only be done to the default Account. Whereas deposits can be done from any of the linked bank accounts</p>
                                <div className='my-3 box-flat-text px-2 py-3'>
                                    <p>Name: {bankDetails?.holderName}</p>
                                    <p>Account Number: {bankDetails.accountNo}</p>
                                    <p>IFSC Code: {bankDetails.ifscCode}</p>
                                </div>
                                <li style={{
                                    color: '#C8C8C8',
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                                }}>Minimum INR Withdrawal 500 RS</li>
                                <li style={{
                                    color: '#C8C8C8',
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                                }}>UPI bank transfers, IMPS, NEFT & RTGS are allowed for this account.</li>
                                <li style={{
                                    color: '#C8C8C8',
                                    fontSize: '16px',
                                    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"'
                                }}>UPI Apps like gPay, PhonePe, Paytm are available.</li>
                            </>}
                        </div>
                    </div>

                    <div className='mt-5 pt-4'>
                        <h4 className='pb-4 ps-3 ps-md-5 fw-bold'>Recent Withdraw <span className='px-3 py-2 ms-1' style={{ color: '#21CA50', fontWeight: '700', fontSize: '16px', background: '#646363', borderRadius: '10px' }}>Claim Withdraw</span></h4>
                        <TableContainer>
                            <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table">
                                <StyledTableHead>
                                    <TableRow>
                                        <StyledTableHeadRow>Time</StyledTableHeadRow>
                                        <StyledTableHeadRow>Type</StyledTableHeadRow>
                                        <StyledTableHeadRow>Asset</StyledTableHeadRow>
                                        <StyledTableHeadRow>Amount</StyledTableHeadRow>
                                        <StyledTableHeadRow>Destination</StyledTableHeadRow>
                                        <StyledTableHeadRow>TxID</StyledTableHeadRow>
                                        <StyledTableHeadRow>Status</StyledTableHeadRow>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>
                                    {withdrawfiatList?.length > 0 && withdrawfiatList.map((row, i) => (
                                        <TableRow key={i}>
                                            <StyledTableCell component="th" scope="row">{dateFormat(row.createdDate)}</StyledTableCell>
                                            <StyledTableCell>{row.type}</StyledTableCell>
                                            <StyledTableCell><span className='tb-img'><img src={row.currencyId.image} alt="Unitic" style={{ width: "20px", height: "20px" }} className="img-fluid img-res" /> </span>&nbsp;{row.currencyId.currencySymbol}</StyledTableCell>
                                            <StyledTableCell>{row.amount}</StyledTableCell>
                                            <StyledTableCell>{bonusAmount(row)}</StyledTableCell>
                                            <StyledTableCell>{shortAdrress(row.txnId != '' ? row.txnId : '-')}</StyledTableCell>
                                            <StyledTableCell>{row.status == 0 ? 'Pending' : row.status == 2 ? 'Rejected' : row.status == 1 ? 'Completed' : 'Processing'}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                    {withdrawfiatList?.length == 0 &&
                                        <TableRow>
                                            <StyledTableCell component="th" scope="row"></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell><img
                                                className="no-record-image"
                                                src="/assets/no-re.png"
                                                alt="no-record"
                                            />
                                                <br />
                                                <span className="text-center">No Records Found</span></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </div>
            </div>
            <Footer />
        </section>
    );
};

export default WithdrawFlatV2;