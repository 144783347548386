import React from 'react'
import { Box, createTheme, ThemeProvider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const FindNextTrading = () => {
  const navigate = useNavigate();
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1400,
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
    <Box
      sx={{
        overflow: 'hidden',
        paddingBottom:'52px',
        marginY: {
          xs: 0,
          md: 0,
        },
      }}
    >
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontWeight: 'bold',
          fontSize: {xs:24,sm:28, md:30, xl:32},
          mt: 2,
          cursor:'default'
        }}
        onClick={()=> navigate('/futures/trade/XBTUSDTM')}
         component='h3'
      >
        Seess Onboarding
      </Typography>
      <Typography
        data-aos='fade-right'
        data-aos-duration='1500'
        sx={{
          fontSize: 14,
          marginBottom: '30px',
          paddingBottom: '10px'
        }}
      >
        Discover the cryptos that are making significant <br />
        gains in the market today
      </Typography>

      <Box
        data-aos='fade-up'
        data-aos-duration='1500'
        sx={{
          display: {
            md: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            gap: 80,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: { md: '50%' },
            marginBottom: { xs: 5, md: 0 },
          }}
        >
          <img className='img-fluid' src='/assets/pc.webp' alt='' />
        </Box>
        <Box className='mx-auto'>
          {[
            {
              title: 'Start Your Trading Journey',
              detail1: 'Register Now for Seess Crypto Transactions',
              detail2: ' and Exciting Opportunities!',
            },
            {
              title: 'Finish Your KYC Verification',
              detail1: 'Complete Your KYC Verification for ',
              detail2: ' Unlimited Trading Potential!',
            },
            {
              title: 'Quick Fiat Deposit',
              detail1: 'Link Your Bank Account for INR ',
              detail2: ' Deposits and Instant Crypto Access',
            },
          ].map((item, i) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  gap: 3,
                  marginBottom: '20px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    bgcolor: i === 0 ? '#21CA50' : '#EFEFEF',
                    height: {xs:'80px', sm: '80px', md: '70px', lg: '85px',xl: '100px'},
                    width: '10px',
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: {xs:20, sm:20, md:18,lg:22, xl:24},
                      fontWeight: 'bold',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography sx={{fontSize: {xs:12, md:12,lg:12,xl:14}}}>{item.detail1}</Typography>
                  <Typography sx={{fontSize: {xs:12, md:12,lg:12,xl:14}}}>{item.detail2}</Typography>
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
    </ThemeProvider>
  )
}

export default FindNextTrading
