import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import '../assets/style.css';
import Config from "../core/config/";
import { makeRequest } from "../core/services/v1/request";
import { useContextData } from "../core/context/index";
import jwt_decode from 'jwt-decode';
import $, { event } from "jquery";
import { getCookie } from "../core/helper/cookie";
import { toast } from "../core/lib/toastAlert";
import { RxCross1 } from 'react-icons/rx';
import { decodeJWT } from '../core/helper/jwt-decode';
import { Form, InputGroup, Spinner } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';
import { showNumber } from '../core/helper/date-format';
import { useNavigate } from 'react-router-dom';

const StyledTableCellFirst = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    color: "white",
    width: "230px !important",
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));
const StyledTableHeadRow = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "22px",
    fontWeight: "500",
    fontStyle: "normal",
    padding: "5px",
    paddingBottom: "16px",
    width: "auto !important",
    color: "white",
    textAlign: 'left'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },
    // lineHeight: "27px"

}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "27px",
    padding: "5px",
    width: "auto !important",
    color: "#C8C8C8",
    textAlign: 'left'
    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    borderBottom: 'none',
    fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"',
    fontSize: "32px",
    fontWeight: "600",
    padding: "5px",
    fontStyle: "normal",
    lineHeight: "38px",
    width: "auto !important",
    color: "white",
    textAlign: 'center'

    // '@media (min-width: 300px)': {
    //     // adjust the width at 600px and above
    //     width: '50px !important',
    // },
    // '@media (min-width: 960px)': {
    //     // adjust the width at 960px and above
    //     width: "50px !important",
    // },

}));

const depositAddressData = [
    { currId: '643531591490e7ffdbccea27', symbol: "BDX", address: "864xEJPqdUUXKtLVkJiVmDdEh5A1DakzQN3kwsQWZAT5cMxrftUCrcnCpn3rjdFbnafExoDd53RgYHDUVAGtgDMG4U4Kdsw", info: 'Deposit In BDX Network Only', id: 1 },
    { currId: '640f648499ad61d2ff936aa2', symbol: "USDT", address: "0x10646DEdB5CE0D90395A19DD16034dFe9dbBbC2D", info: 'Deposit In BEP20 Network Only', id: 3 },
    { currId: '64101bc4c406fcdd920476da', symbol: "TRX", address: "TEEPVAxBGEao1QoAKNUXmJdHbXuhugZzrB", info: 'Deposit In TRC20 Network Only', id: 2 },
]

const SellCryptoPro = () => {
    const [sellCryptoListPro, setSellCryptoListPro] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [loader, setLoader] = useState(true);
    const [entryIdUser, setEntryIdUser] = useState();
    const [walletData, setWalletData] = useState([]);
    const [estimatedValue, setEstimatedValue] = useState({});
    const [defaultCurrencyList, setdefaultCurrencyList] = useState({ currencySymbol: "", image: "" });
    const [buycryptofees, setbuycryptofees] = useState();
    const [buyfees, setbuyfees] = useState();
    const [sellcryptoinrprice, setsellcryptoinrprice] = useState();
    const [buycryptoinrprice, setbuycryptoinrprice] = useState();
    const [sellcryptoinrpricePro, setSellcryptoinrpricePro] = useState();
    const { siteSettings, myProfile } = useContextData();
    const navigate = useNavigate()
    const [currentSymbol, setCurrentSymbol] = useState("USDT");
    const [currentCurrencyId, setCurrentCurrencyId] = useState("");
    const [depositAddress, setDepositAddress] = useState("0x10646DEdB5CE0D90395A19DD16034dFe9dbBbC2D");
    const [spentbuycryptoinrprice, setspentbuycryptoinrprice] = useState();
    const [getTotalInfo, setGetTotalInfo] = useState(0);
    const [INRbalance, setINRbalance] = useState("0");
    const [cryptobalance, setcryptobalance] = useState();
    const [spotWalletData, setSpotWalletData] = useState([]);
    const [buycryptouserlist, setbuycryptouserlist] = useState([]);
    const [pendingorderamount, setpendingorderamount] = useState();
    const [utiprice, setutiprice] = useState();
    const [userid, setuserid] = useState("0");
    const inputRef = useRef(null);
    const [cryptoQuant, setCryptoQuant] = useState();
    const [transactionValue, setTransaction] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [currentFile, setCurrentFile] = useState();
    const [isLoading, setisLoading] = useState(false);
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const sellImg = useRef(null);

    const handleLabelClick1 = () => {
        sellImg?.current.click(); // Trigger the hidden file input when the label is clicked
    };

    useEffect(() => {
        if (siteSettings != null) {
            setbuycryptofees(() => siteSettings['BuyCryptoFees'] + '%');
            // console.log(siteSettings['BuyCryptoExtraPriceForSell'], "siteSettings['BuyCryptoExtraPriceForSell']")
            try {
                const getFee = siteSettings?.BuyCryptoExtraPriceForSellV2.find(el => el?.currency === 'USDT') || {}
                $("#fees").val(getFee?.price || 0);
            }
            catch (e) {

            }
        }

    }, [siteSettings]);

    useEffect(() => {
        const dataFind = async () => {
            await axios.get(`${Config.V1_API_URL}sellcrypto/sell/all`)
                .then(res => {

                    const userSell = (res.data).filter(item => item.sellerID === entryIdUser).reverse();
                    // console.log(userSell);
                    setSellCryptoListPro(() => userSell);

                })
                .catch((e) => console.log(e))
                .finally(() => {

                })
        }
        dataFind();
    }, [refetch, entryIdUser]);

    useEffect(() => {
        async function getWalletCurrency() {
            setLoader(true);
            const params = {
                url: `${Config.V2_API_URL}wallet/getWalletCurrency`,
                method: 'GET'
            };
            const response = await makeRequest(params);

            if (response) {
                const {
                    status = false,
                    data = [],
                    estimateINR = 0,
                    estimateUSD = 0,
                } = response;
                // console.log(response.data, "response wallet");

                // Sort the data based on currencySymbol
                const sortedData = data.sort((a, b) => {
                    if (a.currencySymbol === 'UTI') {
                        return -1; // Move 'UTI' symbol to the front
                    } else if (b.currencySymbol === 'UTI') {
                        return 1; // Move 'UTI' symbol to the front
                    }
                    // For other symbols, maintain their original order
                    return 0;
                });
                setWalletData(() => sortedData);
                setEstimatedValue({
                    estimateINR,
                    estimateUSD
                });

            }
            if (currentCurrencyId == '640f648499ad61d2ff936aa2') {
                const params2 = {
                    url: "https://api.coindcx.com/exchange/ticker",
                    method: 'GET',
                };
                const response2 = await makeRequest(params2);
                if (response2) {
                    const getRes2Origin = response2.find(el => el?.market === 'USDTINR')
                    let price = +(getRes2Origin?.last_price);
                    const {
                        data2 = [],
                    } = response2;
                    // console.log($("#fees").val(), "buyfees")

                    const twoPercent = price * $("#fees").val(); // calculate 2% of the number
                    const result = price + twoPercent;
                    // console.log(result, "result") // add 2% to the number
                    // setbuycryptoinrprice(() => result.toFixed(2));
                    // console.log("enyer", result, typeof result);
                    // let price = Number(result);
                    // setSellcryptoinrpricePro(() => price.toFixed(2));
                    // console.log("enyer", typeof result);
                    setsellcryptoinrprice(price?.toFixed(2));
                    setdefaultCurrencyList({ currencySymbol: 'USDT', image: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png' })
                }
            }
            setLoader(false);
        }
        async function getUserDetails() {
            const token = getCookie('userToken');
            const decodedToken = token ? jwt_decode(token, { PAYLOAD: true }) : {};
            // console.log(decodedToken, "decodedToken");
            setuserid(decodedToken.subject);
        }
        async function getMyProfile() {
            const params = {
                url: `${Config.V1_API_URL}user/getMyProfile`,
                method: 'GET'
            };
            const response = await makeRequest(params);
            if (response) {
                const {
                    data = [],
                } = response;
                // setuserid(response.data._id);
                // console.log("New need", response.data.username, response.data.email);
                setUserName(() => response.data?.username);
                setUserEmail(() => response.data?.email);
                setEntryIdUser(() => response.data._id);
                // console.log(response.data._id, "entryId");
                const payload = {
                    userID: response.data._id
                }
                // console.log(payload, "payload");
                const params2 = {
                    url: `${Config.V1_API_URL}buycrypto/Userbuycryptoodrders`,
                    method: 'POST',
                    body: payload
                };
                const response2 = await makeRequest(params2);
                if (response2) {
                    const {
                        data2 = [],
                    } = response2;
                    // console.log(response2.data, "reponse wallet");
                    setbuycryptouserlist(response2.data);


                    const orderpending = response2.data.filter(element => element.status == 2);
                    const newArray = []; // create an empty array to store the filtered object

                    orderpending.forEach(obj => newArray.push(obj.amount)); // push the filtered object into the new array

                    let sum = 0;

                    for (let i = 0; i < newArray.length; i++) {
                        sum += newArray[i];
                    }

                    setpendingorderamount(sum); // Output: 15
                    localStorage.setItem("pendingbuyorder", sum);
                }


                // walletData.forEach(async (element) => {

                // if(element.currencyId== '640f6a6da85aacd35e842609'){

                // const payload = {
                //   symbol:element.currencyName.toLowerCase()
                // }
                // const params3 = {
                //  url: `${Config.V1_API_URL}buycrypto/buycryptoprice`,
                //  method: 'POST',
                //  body: payload
                //  };
                //  const response3 = await makeRequest(params3);
                //  if (response3) {
                //  const {
                //  data3 = [],
                //  } = response3;
                //  console.log(response3,"asdadasd")
                //  setsellcryptoinrprice(response3.message.toFixed(2));

                //  setsellcryptoinrprice(response3.message.toFixed(2));
                //  }
                // }
                // });
                setutiprice(localStorage.getItem('utiprice'))

            }

        }
        async function getSpotWalletCurrency() {
            const params = {
                url: `${Config.V2_API_URL}wallet/getSpotHoldings`,
                method: 'GET'
            };
            const response = await makeRequest(params);

            if (response) {
                const {
                    status = false,
                    data = []
                } = response;
                // console.log(data, "data");
                data.forEach(element => {
                    if (element.currencySymbol == 'INR') {

                        setINRbalance(element.balance);
                    }
                });

                setSpotWalletData(data);

            }
        }

        getWalletCurrency();
        getUserDetails();
        getMyProfile();
        getSpotWalletCurrency();

    }, [siteSettings]);

    useEffect(() => {
        const defaultBDX = async () => {
            // setLoader(true);
            // console.log("data 1");

            const params2 = {
                url: "https://api.coindcx.com/exchange/ticker",
                method: 'GET',
            };
            const response2 = await makeRequest(params2);
            if (response2) {
                const getRes2Origin = response2.find(el => el?.market === 'USDTINR')
                let price = +(getRes2Origin?.last_price);
                let fees = ''
                if (siteSettings) {
                    try {
                        const getFee = siteSettings?.BuyCryptoExtraPriceForSellV2.find(el => el?.currency === 'BDX') || {}
                        fees = Number(getFee?.price) || 0;
                    }
                    catch (e) { }
                }
                const twoPercent = price * fees; // calculate 2% of the number
                const result = price + twoPercent;
                // console.log(result, "result") // add 2% to the number
                setdefaultCurrencyList({ currencySymbol: 'USDT', image: 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png' })
                setCurrentCurrencyId(() => '640f648499ad61d2ff936aa2')
                setCurrentSymbol(() => 'USDT')
                // console.log(element, "element")
                setSellcryptoinrpricePro(() => result?.toFixed(2));
                setspentbuycryptoinrprice(0);
                setGetTotalInfo(() => 0);

            }
            // setLoader(false);
        }
        defaultBDX();
    }, [siteSettings]);
    async function decimalValue(value, decimal = 0) {
        if (decimal === 0) {
            return showNumber(parseFloat(value));
        }
        return showNumber(parseFloat(value).toFixed(decimal));
    }
    const handleChange = async (event) => {
        setLoader(true);

        const element = walletData.find(el => el.currencyId == event.target.value)

        // console.log(element.currencyId == event.target.value);
        // console.log("data", event.target.value);
        if (element?.currencyId == event.target.value || event.target.value == '643531591490e7ffdbccea27') {
            setCryptoQuant(() => '')
            // console.log(res);
            // walletData.map(({ currencySymbol, currencyId }, index) => {
            //   console.log(currencySymbol, currencyId);
            // }
            // )
            if (event.target.value == "64101d6ac406fcdd920477a0") {
                // setSellcryptoinrpricePro(() => UTIPrice);
                const params = {
                    url: `${Config.V1_API_URL}trade/getMarketsTab?exchangeType=SPOT`,
                    method: "GET",
                };
                const response = await makeRequest(params);
                if (response.status && response.data) {
                    // console.log("price", response.data);
                    const idx = response.data.findIndex(elem => elem.currency == "INR");

                    if (idx > -1) {

                        // (response.data[idx].pairs).map(data => console.log("After pair", data))
                        const findUTIINR = (response.data[idx].pairs).find(data => data?.pair === "UTI_INR");
                        // console.log(findUTIINR);
                        // console.log("Actual price of UTI", );
                        const valueUTI = await decimalValue(findUTIINR.price, findUTIINR.decimalValue);
                        // console.log(typeof valueUTI, valueUTI);
                        let fees = ''
                        if (siteSettings) {
                            try {
                                const getFee = siteSettings?.BuyCryptoExtraPriceForSellV2.find(el => el?.currency === 'UTI') || {}
                                fees = Number(getFee?.price) || 0;
                            }
                            catch (e) { }
                        }
                        const twoPercent = parseFloat(valueUTI) * fees; // calculate 2% of the number
                        const result = parseFloat(valueUTI) + twoPercent; // add 2% to the number
                        // console.log(element, "element")
                        setSellcryptoinrpricePro(() => result?.toFixed(2));
                        setCurrentSymbol(() => element?.currencySymbol);
                        const res2 = depositAddressData.find(item => item?.symbol == element?.currencySymbol);
                        setDepositAddress(() => res2?.address);
                        setspentbuycryptoinrprice(0);
                        setGetTotalInfo(() => 0);
                    }
                }
            }
            else {
                const params2 = {
                    url: "https://api.coindcx.com/exchange/ticker",
                    method: 'GET',
                };
                const response2 = await makeRequest(params2);
                if (response2) {
                    let baseCurrency = event.target.value == '643531591490e7ffdbccea27' ? 'BDX' : element?.currencySymbol;
                    const getRes2Origin = response2.find(el => el?.market === baseCurrency + 'INR')
                    let price = +(getRes2Origin?.last_price) || 0;

                    const {
                        data2 = [],
                    } = response2;
                    let fees = ''
                    if (siteSettings) {
                        try {
                            const getFee = siteSettings?.BuyCryptoExtraPriceForSellV2.find(el => el?.currency === baseCurrency) || {}
                            fees = Number(getFee?.price) || 0;
                        }
                        catch (e) { }
                    }
                    const twoPercent = price * fees; // calculate 2% of the number
                    const result = price + twoPercent; // add 2% to the number
                    // console.log(element, "element")
                    setSellcryptoinrpricePro(() => result?.toFixed(2));

                    setspentbuycryptoinrprice(0);
                    setGetTotalInfo(() => 0);

                    setCurrentCurrencyId(() => event.target.value)
                    setCurrentSymbol(() => baseCurrency);
                    const res2 = depositAddressData.find(item => item?.symbol == baseCurrency);
                    setDepositAddress(() => res2?.address);

                }
            }

            setLoader(false);
            // let inr = buycryptoinrprice * cryptoQuantity;
            // let getTotal = inr - (0.4 / 100);
            // let symbol = $('#buycurrencysymbol option:selected').text();
            // setCurr(() => symbol);
            // setGetTotalInfo(() => (getTotal).toFixed(6) + ' ' + symbol);

        }
    }
    const handleCopyClick = () => {
        let type = "success";
        inputRef.current.select();
        document.execCommand('copy');
        toast({ type, message: "Copeid" });
    }

    const handleChange2 = (event) => {
        let fees = 0;
        if (siteSettings) {
            fees = Number(siteSettings['BuyCryptoFees']);
        }
        setCryptoQuant(() => event.target.value);
        let inr = sellcryptoinrpricePro * Number(event.target.value);
        // console.log(typeof sellcryptoinrpricePro, typeof event.target.value, inr, typeof inr);

        const percentage = (fees + 1) / 100;
        // let getTotal = inr - inr * percentage;

        if (inr > 0) {

            let getTotal = inr - (inr * percentage);
            setGetTotalInfo(() => (getTotal).toFixed(2));
        }
        else {
            setGetTotalInfo(() => (0).toFixed(2));
        }
    }
    const selectFile = (e) => {
        setCurrentFile(() => e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
    };


    const handleSellSubmit = async () => {
        // console.log("pic", e.target.img.files[0]);
          if (myProfile && (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1 || myProfile?.kycOffline?.identity?.status != 1)) {
              toast({ type: "error", message: "Please complete the kyc details" });
              navigate('/my/identification')
              return false;
            }
        setisLoading(true)
        const symbolFinding = walletData.find(item => item.currencySymbol === currentSymbol);
        const imgCurrency = currentCurrencyId === '643531591490e7ffdbccea27' ? 'https://res.cloudinary.com/dkqixjgxa/image/upload/v1681207682/Images/mejachvugmzquqd3vamu.png' : symbolFinding?.image
        // console.log(symbolFinding);
        let entryId = entryIdUser;
        let photo = currentFile;
        let cryptoQuantity = cryptoQuant;
        let transaction = transactionValue;
        let getBalance = getTotalInfo;
        // console.log("wanted", currentFile);
        const formDataSell = new FormData();
        formDataSell.append('sellerID', entryId);
        formDataSell.append('symbolImg', imgCurrency);
        formDataSell.append('img', currentFile);
        formDataSell.append('cryptoQuantity', cryptoQuantity);
        formDataSell.append('transaction', transaction);
        formDataSell.append('getBalance', getBalance);
        formDataSell.append('currency', currentSymbol);
        formDataSell.append('userName', userName);
        formDataSell.append('userEmail', userEmail);
        let type = "error";
        let type2 = "success";
        if (!photo
            || !cryptoQuantity
            || !transaction) {
            setisLoading(false)
            return toast({ type, message: "Fill Up All Details" });
        }
        else {
            let token = getCookie("userToken");
            const isValid = decodeJWT(token);
            if (isValid) {
                await axios.post(`${Config.V1_API_URL}sellcrypto/sell?type=sellcrypto`, formDataSell, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    }
                })
                    .then(res => {
                        let type = "error";
                        if (res.status == 200) {
                            type = 'success';
                            toast({ type, message: res.data.message });
                            setInterval(function () { window.location.reload() }, 2000);
                            setTransaction(null);
                            setGetTotalInfo(0);
                            setPreviewImage("");
                            setCryptoQuant(null);
                            setCurrentFile(null);
                            setRefetch(!refetch);

                        } else {
                            toast({ type, message: res.data.message });
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => { setisLoading(false); setRefetch(!refetch); });
            }
            setisLoading(false)
        }
    }


    const copyToClipboard = (get) => {
        let type = "success";
        toast({ type, message: "Copied" });
    }
    return (
        <section className='container pb-5 pt-3'>

            <div className='sell-box mx-auto my-4'>

                <div className='mx-auto pt-3' style={{ width: '90%' }}>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group className="my-3 " >

                            <div className="custom2-select-wrapper">
                                <Form.Select aria-label="Default select example" className={`trade-input borderRadiusIssue`} disabled={loader} onChange={handleChange} required>
                                    {/* <option value="643531591490e7ffdbccea27" >BDX</option> */}
                                    <option value="640f648499ad61d2ff936aa2" >USDT</option>
                                    <option value="64101bc4c406fcdd920476da" >TRX</option>
                                    {/* {walletData.map(({ currencySymbol, currencyId }, index) => {
                                                // console.log(currencySymbol, currencyId);
                                                if (currencySymbol == 'UTI' || currencySymbol == 'QDT' || currencySymbol == 'BDX' || currencySymbol == 'ETH' || currencySymbol == 'USDT-trx' || currencySymbol == 'BNB' || currencySymbol == 'TRX') {
                                                    return (

                                                        <option value={currencyId}>{currencySymbol}</option>
                                                    )
                                                }

                                            }
                                            )} */}
                                </Form.Select>
                                <div className="custom2-select-arrow"></div>
                            </div>
                        </Form.Group>
                        <Form.Group className="mb-3 inputcontainer" controlId="exampleForm.ControlInput1">
                            <Form.Control type="text" value={`Price: ${loader ? '' : sellcryptoinrpricePro}`} className='trade-input borderRadiusIssue' readOnly required />
                            {loader ? <div className="icon-container">
                                <Spinner animation="border" size="sm" />
                            </div> : null}
                        </Form.Group>

                        <div className='d-flex justify-content-between align-items-center'>
                            <p><span style={{ fontWeight: 'bold' }}>Deposit Address </span>: {loader ? <Spinner animation="border" size="sm" /> : depositAddress?.slice(0, 15) + '..xx'}</p>
                            <p><CopyToClipboard text={`${depositAddress}`} onCopy={copyToClipboard}>
                                <button className='sell-cpy px-3 py-1' disabled={loader}>COPY</button>
                            </CopyToClipboard></p>
                        </div>
                        <div className='mb-3 '>
                            <p><small>
                                {
                                    (() => {
                                        let current = depositAddressData.find(el => el.currId === currentCurrencyId);
                                        return current?.info;
                                    })()
                                }</small></p>
                        </div>
                        <InputGroup className="mb-3 ">
                            <InputGroup.Text id="basic-addon1" className='trade-input' style={{ borderRadius: '69px 0px 0px 69px' }}>{loader ? <Spinner animation="border" size="sm" className='me-2' /> : currentSymbol} Quantity Sent :</InputGroup.Text>
                            <Form.Control type="text" placeholder="" className='trade-input ' aria-label="Username" style={{ borderRadius: '0px 69px 69px 0px' }}
                                aria-describedby="basic-addon1"
                                value={cryptoQuant}
                                onChange={handleChange2} />
                        </InputGroup>

                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1" style={{ borderRadius: '69px 0px 0px 69px' }} className='trade-input'>Transaction Hash :</InputGroup.Text>
                            <Form.Control type="text" placeholder="" className='trade-input' aria-label="Username" style={{ borderRadius: '0px 69px 69px 0px' }}
                                aria-describedby="basic-addon1" value={transactionValue} onChange={(e) => setTransaction(e.target.value)} required />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon18" className='trade-input' style={{ borderRadius: '69px 0px 0px 69px' }}>Upload Image :</InputGroup.Text>
                            <Form.Control type="text" readOnly
                                style={{ cursor: 'pointer' }}
                                accept=".png,.jpeg,.jpg" require placeholder="" className='trade-input' aria-label="Username" value={currentFile?.name}
                                aria-describedby="basic-addon18" onClick={() => handleLabelClick1()} />

                            <Form.Control type="file" onChange={selectFile}
                                style={{ display: "none" }} ref={sellImg}
                                accept=".png,.jpeg,.jpg" require placeholder="" className='trade-input' aria-label="Username"
                                aria-describedby="basic-addon179" />
                            <InputGroup.Text id="basic-addon179" className='trade-btn-input' style={{ borderRadius: '0px 69px 69px 0px' }} onClick={() => handleLabelClick1()}>Upload</InputGroup.Text>
                        </InputGroup>
                        {previewImage && (
                            <div>
                                <p className="mb-1 text-end" style={{ cursor: "pointer" }}><RxCross1 onClick={() => {
                                    setPreviewImage("");
                                    setCurrentFile("");
                                }} /></p>
                                <img
                                    src={previewImage}
                                    alt="logo"
                                    width={50}
                                    className="mx-auto"
                                />{" "}
                            </div>
                        )}

                        <div style={{ fontFamily: 'Roboto, "PingFang SC", -apple-system, BlinkMacSystemFont, "Microsoft YaHei"' }} className='d-flex justify-content-between align-items-center'>
                            <p>Fees + GST: {buycryptofees}</p>
                            <p>TDS :1.00%</p>
                        </div>

                        <InputGroup className="mb-3 mt-3">
                            <InputGroup.Text id="basic-addon1" className='trade-input' style={{ borderRadius: '69px 0px 0px 69px' }}>You Get :</InputGroup.Text>
                            <Form.Control type="text" placeholder="" className='trade-input' aria-label="Username"
                                aria-describedby="basic-addon1" value={getTotalInfo} readOnly required />
                            <InputGroup.Text id="basic-addon1" className='trade-input' style={{ borderRadius: '0px 69px 69px 0px' }}>INR</InputGroup.Text>
                        </InputGroup>
                        <div className='mx-auto text-center my-3'>
                            <button className='btn-sell px-5 mb-3' onClick={() => handleSellSubmit()} disabled={isLoading || loader}>SELL</button>
                        </div>
                    </form>
                </div>

            </div>

            {sellCryptoListPro.length > 0 &&
                <div className="">
                    <div className='my-5 pt-4 '>
                        <TableContainer >
                            <Table sx={{ minWidth: window.innerWidth < 700 ? 1000 : 100, justifyContent: 'center' }} aria-label="simple table" >
                                <StyledTableHead>
                                    <TableRow >
                                        <StyledTableHeadRow>#</StyledTableHeadRow>
                                        <StyledTableHeadRow>Currency</StyledTableHeadRow>
                                        <StyledTableHeadRow>Quantity</StyledTableHeadRow>
                                        <StyledTableHeadRow>INR Amount</StyledTableHeadRow>
                                        <StyledTableHeadRow>Type</StyledTableHeadRow>
                                        <StyledTableHeadRow>Status</StyledTableHeadRow>
                                        <StyledTableHeadRow>Date</StyledTableHeadRow>
                                    </TableRow>
                                </StyledTableHead>
                                <TableBody>
                                    {sellCryptoListPro.length > 0 && sellCryptoListPro.map((row, i) => {
                                        if (row) {
                                            return (
                                                <TableRow key={row.name}>
                                                    <StyledTableCell >
                                                        {i + 1}
                                                    </StyledTableCell>
                                                    <StyledTableCell><span className='d-flex justify-content-start align-items-cneter' ><img src={row?.symbolImg} alt="" style={{ width: "30px" }} /><span className='text-light ps-2'> {row?.currency}</span></span></StyledTableCell>
                                                    <StyledTableCell>{row?.cryptoQuantity}</StyledTableCell>
                                                    <StyledTableCell>{row?.getBalance}</StyledTableCell>
                                                    <StyledTableCell>Sell</StyledTableCell>
                                                    <StyledTableCell>{row?.status}</StyledTableCell>
                                                    <StyledTableCell>{row?.createdAt}</StyledTableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            }
        </section >
    );
};

export default SellCryptoPro;