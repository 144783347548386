import React, { useState, useEffect } from "react";
import '../../assets/style.css';
import Config from "../../core/config/";
import { toast } from "../../core/lib/toastAlert";
import axios from "axios";
import LoaderUnitic from "../../Component/Loader/LoaderUnitic";
import { countDecimals } from "../../core/helper/common";
import TradingViewSpot from "../separate/usdtTrading/tradeChartUsdt/trading-view-usdt";
import { useParams } from "react-router-dom";
let priceObj = null;
const SpotChartUsdt = (props) => {
  const [pairsLoader, setPairsLoader] = useState(true)
  const { pairName } = useParams();
  const pairDataFetch = async () => {
    setPairsLoader(true);
    await axios.get(`${Config?.V3_API_URL}trade/get-pair?pair=${pairName}`)
      .then(res => {
        if (res.data?.data?.orderBook.code != '200000' || !res.data?.status) {
          toast({ type: "error", message: res.data?.data?.orderBook?.msg || 'An error ocurred!' });
          return null;
        }
        const getQuoteDecimal = countDecimals(res.data?.data?.pair?.data?.quoteIncrement);
        const getBaseDecimal = countDecimals(res.data?.data?.pair?.data?.baseIncrement);
        const getPriceDecimal = countDecimals(res.data?.data?.pair?.data?.priceIncrement);
        priceObj = res.data?.data?.rate;
        priceObj = { ...priceObj, baseDecimal: getBaseDecimal, quoteDecimal: getQuoteDecimal, priceDecimal: getPriceDecimal }
      })
      .catch(err => {
        console.log(err);
        return null;
      })
      .finally(() => {
        setPairsLoader(() => false)
      })
  }
  useEffect(() => {
    pairDataFetch()

  }, []);
  return (
    <div className='bg-default text-white pb-0 overflow-hidden min-vh-100'>
      <div className=''>
        <div className='container-fluid p-0 overflow-hidden'>
          <div className='col-lg-12 mx-auto trading-page-box'>
            <div className='row p-0'>
              <div className='col-lg-9 trading-page-box-left-inner-section pe-lg-0'>
                <div className=''>
                  <div className="trading-inner-right-section">
                    <div id="tradingviewwidgetFutures">
                      {(pairName && !pairsLoader) ? <TradingViewSpot
                        symbol={pairName}
                        theme={props.theme}
                        dec={priceObj?.priceDecimal}
                      /> : <LoaderUnitic />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotChartUsdt;