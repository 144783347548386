import { toast } from "../lib/toastAlert";

export const isEmpty = (str) => {
  try {
    if (str && str != undefined && str != "") {
      return false;
    }
    return true;
  } catch (e) {
    return true;
  }
}

export const copyText = (data = {}) => {
  if (data.text) {
    var input = document.createElement("input");
    document.body.appendChild(input);
    input.value = data.text;
    input.select();
    document.execCommand("Copy");
    input.remove();
    toast({ type: "success", message: data.message });
  }
}
export const countDecimals = (value) => {
  const valueString = Number(value).toString();

  if (Math.floor(value) === value) return 0;
  const splitValue = valueString.split(".");

  if (splitValue.length === 2) {
    return splitValue[1].length;
  }

  if (valueString.includes('e-')) {
    const decimalPlaces = Math.abs(Number(valueString.split('e-')[1]));
    const leadingZeros = decimalPlaces - (splitValue[1] ? splitValue[1].length : 0);
    return leadingZeros > 0 ? leadingZeros + (splitValue[1] ? splitValue[1].length : 0) : splitValue[1].length;
  }

  return 0;
};


export const pageAllowCheck = (myProfile, pageName = "") => {
  let retData = {
    type: "success",
  };

  if (pageName === "final" && isEmpty(myProfile.email)) {
    retData = {
      type: "error",
      message: "Please update your email address.",
      navigate: "/my/email-authenticator",
    };
    return retData;
  }

  if (pageName === "final" && isEmpty(myProfile.phoneno)) {
    retData = {
      type: "error",
      message: "Please update your phone number.",
      navigate: "/my/sms-authenticator",
    };
    return retData;
  }

  if (
    pageName === "final" &&
    (isEmpty(myProfile.kycstatus) || (myProfile.kycstatus != 1 || myProfile?.kycOffline?.aml?.status != 1 || myProfile?.kycOffline?.identity?.status != 1))
  ) {
    retData = {
      type: "error",
      message: "Please complete the kyc verification.",
      navigate: "/my/identification",
    };
    return retData;
  }

  if (
    pageName === "final" &&
    (isEmpty(myProfile.tfaStatus) || myProfile.tfaStatus === 0)
  ) {
    retData = {
      type: "error",
      message: "Please complete the 2FA process.",
      navigate: "/google-authenticator",
    };
    return retData;
  }

  return retData;
};

export const clickNavigate = (data = {}, myProfile = {}) => {
  const {
    type = "", row = {}, url = ""
  } = data;

  let kycUserType = "";

  if (myProfile && myProfile._id && myProfile.country) {
    kycUserType = myProfile.country === "IND" ? myProfile.country : "International";
  }

  if (type === "deposit" || type === "withdraw") {
    if (row.currencySymbol === "INR") {
      if (kycUserType === "International") {
        toast({ type: "error", message: "This facility is currently un-available for your country." });
        return { status: false };
      }
    }
  }
  if (url != "") {
    return { status: true, url };
  }
  return { status: true };
}